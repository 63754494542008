<template>
  <v-row
    no-gutters
    class="align-start justify-center fill-height"
    style="height: 100%; overflow: hidden"
  >
    <v-col cols="12" md="6" style="height: 100% !important">
      <v-row
        no-gutters
        class="align-start justify-center"
        style="height: 100% !important"
        v-click-outside="handleClickOutside"
      >
        <v-col cols="12">
          <v-card
            class="rounded-t-0 rounded-b-lg"
            :class="$vuetify.breakpoint.smAndDown ? '' : 'pt-1'"
            :style="[
              { height: $vuetify.breakpoint.smAndDown ? '56px' : '64px' },
            ]"
          >
            <v-card-text class="pa-2">
              <v-text-field
                ref="searchInput"
                v-model="search"
                :placeholder="$i18n.t('Search in uContact...')"
                prepend-inner-icon="mdi-magnify"
                dense
                outlined
                rounded
                hide-details="auto"
                @input="handleSearch()"
                autocomplete="new-password"
              >
                <template #append>
                  <loader v-if="loading" :size="24" />
                  <v-icon
                    v-else
                    @click="
                      $emit('close');
                      search = null;
                    "
                  >
                    mdi-close
                  </v-icon>
                </template>
              </v-text-field>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          class="my-3"
          :style="[
            {
              height: getListHeight,
            },
          ]"
          style="overflow: auto"
        >
          <v-expand-transition>
            <v-card
              class="rounded-lg pa-3"
              v-show="search && !loading && !writing"
            >
              <v-list class="pa-0 overflow-y-auto rounded-lg transparent">
                <v-subheader class="primary--text overline" v-if="!hasItems">{{
                  $i18n.t("No results for search")
                }}</v-subheader>

                <v-list-item-group v-else color="primary">
                  <div
                    v-for="resource in Object.keys(resources)"
                    :key="resource"
                  >
                    <v-subheader
                      class="primary--text overline"
                      v-if="resources[resource]?.length"
                    >
                      {{ $i18n.t(resource) }}
                    </v-subheader>
                    <SearchItem
                      v-for="(item, index) in resources[resource]"
                      :key="index"
                      :data="{
                        title: item.name,
                        subtitle: item.username,
                        phone: item.phone,
                        avatar: resourceAvatar[resource],
                        userAvatar: item.avatar,
                        icon: resourceIcon[resource],
                        resource: resource,
                      }"
                      @click.native="handleClick(resource, item)"
                    />
                  </div>
                </v-list-item-group>

                <v-list-item-group color="primary">
                  <v-subheader
                    class="primary--text overline"
                    v-if="chats.length"
                    >{{ $i18n.t("Chats") }}</v-subheader
                  >
                  <SearchItem
                    v-for="(item, index) in chats"
                    :key="index"
                    :data="item"
                    :chat="true"
                    @click.native="openChat(item)"
                    @makeCall="makeCall"
                  />
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-expand-transition>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Analytics from "@/controllers/analytics";
import SearchItem from "@/components/menu/searchBar/SearchItem";
import { useInboxStore, inbox, useAppStore, usePhoneStore } from "@/utils";

export default {
  name: "SearchBar",

  components: {
    SearchItem,
  },
  props: {
    open: {
      type: Boolean,
    },
  },
  data() {
    return {
      search: "",
      resources: {},
      users: [],
      campaigns: [],
      connectors: [],
      profiles: [],
      chats: [],
      loading: false,
      timer: null,
      writing: false,
      resourceAvatar: { users: true },
      resourceIcon: {
        users: "mdi-account-outline",
        profiles: "mdi-badge-account-horizontal-outline",
        connectors: "mdi-connection",
        campaigns: "mdi-account-group-outline",
        dispositions: "mdi-shape-outline",
        holidays: "mdi-calendar-today-outline",
        templates: "mdi-file-document-outline",
        status: "mdi-coffee-outline",
        tasks: "mdi-clipboard-list-outline",
        dialers: "mdi-hub-outline",
        ivrs: "mdi-sitemap-outline",
        bots: "mdi-robot-outline",
        webhooks: "mdi-hook",
        wallboards: "mdi-chart-bar",
        forms: "mdi-list-box-outline",
        phonebook: "mdi-bookmark-outline",
      },
      resourceTab: {
        users: "users",
        profiles: "users",
        connectors: "connectors",
        campaigns: "campaigns",
        dispositions: "campaigns",
        holidays: "campaigns",
        templates: "campaigns",
        status: "campaigns",
        tasks: "campaigns",
        phonebook: "campaigns",
        dialers: "automations",
        ivrs: "automations",
        bots: "automations",
        webhooks: "automations",
        wallboards: "wallboards",
        forms: "forms",
      },
    };
  },

  methods: {
    async focusInput() {
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },

    async openChat(item) {
      let guid = "";
      let campaign = "";
      let type = "";
      if (item.avatar) {
        guid =
          item.subtitle < useAppStore().user.username
            ? `${item.subtitle}|${useAppStore().user.username}`
            : `${useAppStore().user.username}|${item.subtitle}`;
        type = "agent";
      } else {
        guid = item.title;
        campaign = item.title;
        type = "campaign";
      }
      if (
        !useInboxStore().interactions.find(
          interaction => interaction.guid == guid
        )
      ) {
        await inbox().startInteraction(
          { clientId: item.avatar ? item.subtitle : item.title },
          campaign,
          type,
          {},
          {}
        );
      }
      this.$router.push({
        path: "/redirect/inbox",
        query: { search: guid },
      });
      this.$emit("changeRoute");
      this.$emit("close");
    },

    makeCall(contact) {
      usePhoneStore().getPhone().makeCall(contact.phone, "");
      this.$router.push({
        path: "/redirect/inbox",
        query: { name: contact.phone },
      });
      this.$emit("changeRoute");
      this.$emit("close");
    },
    handleSearch() {
      clearTimeout(this.timer);
      this.writing = true;
      this.timer = setTimeout(async () => {
        if (this.search) {
          this.writing = false;
          this.loading = true;
          this.resources = {};
          this.chats = [];
          let res = await Analytics.search(this.search.toLowerCase());
          if (res.success) {
            let resources = JSON.parse(res.res.request.response).result;
            Object.keys(this.resourceIcon).forEach(r => {
              this.resources[r] = resources[r];
              if (r == "phonebook" && !useInboxStore().phonebook.length) {
                this.resources[r] = [];
              }
            });
            this.chats = useInboxStore().campaigns.map(campaign => {
              return {
                title: campaign.name,
                subtitle: "",
                icon: "mdi-account-group-outline",
                data: "",
                avatar: false,
                campaign: true,
              };
            });
            this.chats = this.chats.concat(
              useInboxStore().contacts.map(contact => {
                return {
                  title: contact.name,
                  subtitle: contact.username,
                  icon: "mdi-account-outline",
                  data: "",
                  avatar: true,
                  phone: contact.phone,
                  connected: contact.connected,
                  userAvatar: contact.avatar,
                };
              })
            );
            this.chats = this.chats.filter(
              chat => chat.subtitle != useAppStore().user.username
            );
            this.chats = this.chats.filter(e =>
              this.removeAccents(e.title)
                .toLowerCase()
                .includes(this.removeAccents(this.search).toLowerCase())
            );
          } else {
            this.$notify({
              group: "app",
              duration: 2000,
              text: "Error",
            });
          }
          this.loading = false;
        }
      }, 500);
    },
    handleClickOutside() {
      if (this.$props.open) this.$emit("close");
    },
    handleClick(resource, item) {
      if (resource == "phonebook") {
        this.$router.push({
          path: "/redirect/inbox",
          query: { search: item.key, resource: resource },
        });
      } else if (resource === "dispositions" && item.campaign) {
        this.$router.push({
          path: "/redirect/campaigns",
          query: {
            search: item.key,
            resource: resource,
            campaign: item.campaign,
          },
        });
      } else {
        this.$router.push({
          path: "/redirect/" + this.resourceTab[resource],
          query: { search: item.key, resource: resource },
        });
      }

      this.$emit("changeRoute");
      this.$emit("close");
    },
    removeAccents(input) {
      return input.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
  },

  computed: {
    getListHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "calc(100% - 68px)";
      } else {
        return "calc(100% - 76px)";
      }
    },
    hasItems() {
      return (
        !!Object.keys(this.resources).find(k => this.resources[k]?.length) ||
        this.chats.length
      );
    },
  },
};
</script>
