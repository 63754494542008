var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormBasicLayout',{attrs:{"size":"xl","showActions":false,"padding":'pa-0',"closeMobile":false},scopedSlots:_vm._u([{key:"form",fn:function(){return [(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-tabs',{attrs:{"vertical":!_vm.$vuetify.breakpoint.mdAndDown,"icons-and-text":_vm.$vuetify.breakpoint.mdAndDown,"show-arrows":_vm.$vuetify.breakpoint.mdAndDown,"centered":_vm.$vuetify.breakpoint.mdAndDown},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(tab,index){return _c('v-tab',{key:index,class:[{ 'justify-start': !_vm.$vuetify.breakpoint.mdAndDown }]},[(!_vm.$vuetify.breakpoint.mdAndDown)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(tab.icon)+" ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.t(tab.text))+" ")]),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-icon',[_vm._v(" "+_vm._s(tab.icon)+" ")]):_vm._e()],1)}),1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"width":"100%","height":"100%"}},[_c('Loader',{attrs:{"size":150}})],1):_vm._e(),(!_vm.loading)?_c('v-row',{staticClass:"fill-height",style:({
        height: _vm.$vuetify.breakpoint.mdAndDown
          ? _vm.selectedTab == 0
            ? 'calc(100% - 72px)'
            : 'calc(100% - 133px)'
          : null,
      }),attrs:{"no-gutters":""}},[(
          !_vm.$vuetify.breakpoint.mdAndDown ||
          (_vm.$vuetify.breakpoint.mdAndDown && _vm.selectedTab != 3)
        )?_c('v-col',{staticStyle:{"max-height":"100%","overflow":"auto"},attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? '12' : '9'}},[_c('v-card',{staticClass:"pa-10 fill-height overflow-hidden",style:(_vm.$vuetify.theme.dark
              ? 'background-color: var(--v-background-base) !important'
              : ''),attrs:{"elevation":"0"}},[_c('div',{staticClass:"d-flex flex-column fill-height align-content-start justify-center"},[(
                !_vm.$vuetify.breakpoint.mdAndDown ||
                (_vm.$vuetify.breakpoint.mdAndDown && _vm.selectedTab == 0)
              )?_c('div',{staticClass:"pa-0 pb-3"},[_c('div',{staticClass:"d-flex align-center",class:_vm.$vuetify.breakpoint.mdAndDown ? 'flex-column' : null},[_c('div',{style:({
                    width: !_vm.$vuetify.breakpoint.mdAndDown ? '10%' : null,
                  })},[_c('UserAvatar',{ref:"userAvatar",attrs:{"offset":_vm.$vuetify.breakpoint.mdAndDown ? 60 : 28,"user":_vm.userInfo,"status":_vm.userInfo.status,"size":_vm.$vuetify.breakpoint.mdAndDown ? 180 : 90,"dashboardAgent":true}})],1),_c('div',{style:({
                    width: !_vm.$vuetify.breakpoint.mdAndDown ? '80%' : null,
                  })},[_c('v-row',{class:!_vm.$vuetify.breakpoint.mdAndDown ? 'pl-7' : null,attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",class:_vm.$vuetify.breakpoint.mdAndDown
                          ? 'flex-column py-3'
                          : null,attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h5 font-weight-bold primary--text"},[_vm._v(" "+_vm._s(_vm.$i18n.t("Hi", { name: _vm.userInfo.name }))+" ")]),_c('span',{class:!_vm.$vuetify.breakpoint.mdAndDown ? 'ml-5' : null,staticStyle:{"font-size":"16px"}},[_c('span',{staticClass:"text-body-1",style:({
                            color: _vm.$vuetify.theme.dark
                              ? '#fff !important'
                              : '#505253 !important',
                          })},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-phone-outline ")]),_vm._v(" "+_vm._s(_vm.userInfo.phone)+" ")],1)])]),_c('v-col',{class:_vm.$vuetify.breakpoint.mdAndDown ? 'py-3' : 'pt-1',style:({
                        'text-align': _vm.$vuetify.breakpoint.mdAndDown
                          ? 'center'
                          : null,
                      }),attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold agent-title-color",staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.$i18n.t(_vm.generateText.text, { value: _vm.generateText.param, }))+" ")])])],1)],1),(!_vm.$vuetify.breakpoint.mdAndDown)?_c('div',{staticStyle:{"width":"10%"}},[_c('BotPlaceholder',{staticClass:"botPlaceholder pt-2 pr-2",attrs:{"type":"hello","showText":false,"justBot":"","styles":{
                      transform: 'scaleX(-1)',
                      height: '90px',
                    },"showHeightBot":false}})],1):_vm._e()])]):_vm._e(),(
                !_vm.$vuetify.breakpoint.mdAndDown ||
                (_vm.$vuetify.breakpoint.mdAndDown && _vm.selectedTab == 1)
              )?_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown ? 'fill-height' : null,staticStyle:{"min-height":"170px !important"}},[_c('v-row',{staticClass:"fill-height align-center",attrs:{"no-gutters":""}},_vm._l((_vm.computedChannels),function(channel,index){return _c('v-col',{key:index,class:_vm.$vuetify.breakpoint.mdAndDown ? 'my-2' : null,staticStyle:{"position":"relative","height":"170px"},attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? '6' : null}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticStyle:{"width":"100%"}},[_c('v-fade-transition',[(!hover)?_c('InteractionCard',{staticStyle:{"position":"absolute","width":"97%"},style:(_vm.$vuetify.theme.dark
                              ? 'background-color: var(--v-background-accent) !important'
                              : ''),attrs:{"channel":channel,"inbound":_vm.interactionStats[channel.value + 'In'],"outbound":_vm.interactionStats[channel.value + 'Out'],"disabled":channel.disabled}}):_vm._e()],1),_c('v-fade-transition',[(hover)?_c('InteractionCard',{staticStyle:{"position":"absolute","width":"97%"},style:(_vm.$vuetify.theme.dark
                              ? 'background-color: var(--v-background-accent) !important'
                              : ''),attrs:{"channel":channel,"inbound":_vm.interactionStats[channel.value + 'In'],"outbound":_vm.interactionStats[channel.value + 'Out'],"time":_vm.interactionStats[channel.value + 'Time'] || 0,"disabled":channel.disabled}}):_vm._e()],1)],1)]}}],null,true)})],1)}),1)],1):_vm._e(),(
                !_vm.$vuetify.breakpoint.mdAndDown ||
                (_vm.$vuetify.breakpoint.mdAndDown && _vm.selectedTab == 2)
              )?_c('div',{staticClass:"d-flex fill-height py-5",class:_vm.$vuetify.breakpoint.mdAndDown ? 'flex-column' : null,staticStyle:{"max-height":"100%"}},[_c('div',{staticClass:"pa-3",class:_vm.$vuetify.breakpoint.mdAndDown ? null : 'pl-0',style:({
                  width: _vm.$vuetify.breakpoint.smAndDown
                    ? '100% !important'
                    : '60% !important',
                  height: '100% !important',
                })},[_c('v-card',{staticClass:"d-flex flex-column fill-height",staticStyle:{"box-shadow":"0px 0px 10.143px 0px rgba(0, 0, 0, 0.15) !important","border-radius":"20px"},style:(_vm.$vuetify.theme.dark
                      ? 'background-color: var(--v-background-accent) !important'
                      : '')},[_c('AutomaticChart',{ref:"chart",staticClass:"pa-3",staticStyle:{"flex":"1"},attrs:{"viewOnly":true,"chart":_vm.statsChart,"sources":_vm.chartSources,"globalSearch":_vm.chartFilters,"externalData":[_vm.statsChartData]}}),_c('div',[_c('v-row',{staticClass:"pa-2 pt-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-space-around flex-wrap",attrs:{"cols":"12"}},_vm._l((_vm.statsMetrics),function(item,index){return _c('span',{key:index,staticClass:"pa-1 text-caption d-flex algin-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.clickLegendStats(item)}}},[_c('v-icon',{staticClass:"px-1",attrs:{"size":"12","color":item.color}},[_vm._v(" mdi-circle ")]),_c('span',{style:([
                              {
                                'text-decoration': !item.active
                                  ? 'line-through'
                                  : null,
                              },
                              {
                                color: _vm.$vuetify.theme.dark
                                  ? '#C4C4C4'
                                  : '#545454',
                              },
                            ])},[_vm._v(" "+_vm._s(_vm.$i18n.t(item.id))+" ")])],1)}),0)],1)],1)],1)],1),_c('div',{staticClass:"pa-3 fill-height",class:_vm.$vuetify.breakpoint.mdAndDown ? null : 'pr-0',style:({
                  width: _vm.$vuetify.breakpoint.smAndDown
                    ? '100% !important'
                    : '40% !important',
                })},[_c('v-card',{staticClass:"d-flex flex-column fill-height",staticStyle:{"box-shadow":"0px 0px 10.143px 0px rgba(0, 0, 0, 0.15) !important","border-radius":"20px"},style:(_vm.$vuetify.theme.dark
                      ? 'background-color: var(--v-background-accent) !important'
                      : '')},[_c('AutomaticChart',{staticClass:"px-3 agent-card-chart-status",staticStyle:{"flex":"1 1 100%"},attrs:{"viewOnly":true,"chart":_vm.statusChart,"sources":_vm.chartSources,"dataFilters":_vm.chartFilters,"externalData":[_vm.statusChartData]}})],1)],1)]):_vm._e()])])],1):_vm._e(),(
          !_vm.$vuetify.breakpoint.mdAndDown ||
          (_vm.$vuetify.breakpoint.mdAndDown && _vm.selectedTab == 3)
        )?_c('v-col',{staticClass:"fill-height",style:(_vm.$vuetify.theme.dark
            ? 'background-color: var(--v-background-accent) !important'
            : ''),attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? '12' : '3'}},[_c('v-row',{staticClass:"pa-3 fill-height",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center pa-3 pb-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold agent-title-color",staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.$i18n.t("Recent activity"))+" ")]),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-filter-outline")])],1)]}}],null,false,3162593131)},[_c('v-card',{staticStyle:{"width":"155px"}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.timelineButtons),function(item,index){return _c('v-col',{key:index,staticClass:"pa-3",attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"ma-0",attrs:{"label":_vm.$i18n.t(item.text),"color":"primary","hide-details":"auto"},model:{value:(_vm.timelineFilters[item.value]),callback:function ($$v) {_vm.$set(_vm.timelineFilters, item.value, $$v)},expression:"timelineFilters[item.value]"}})],1)}),1)],1)],1),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-clock-outline")])],1)]}}],null,false,2708099808)},[_c('v-card',{staticStyle:{"width":"155px"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-list',{attrs:{"flat":""}},_vm._l((_vm.groupTimes),function(option,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.handleTimeLineTime(option)}}},[_c('v-list-item-title',{class:_vm.selectedGroupTime == option.time
                              ? 'font-weight-bold primary--text'
                              : ''},[_vm._v(" "+_vm._s(option.text)+" ")])],1)}),1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"pa-3 fill-height overflow-auto",staticStyle:{"max-height":"calc(100% - 36px) !important"},attrs:{"cols":"12"}},[(!_vm.loadingTimeline)?_c('div',_vm._l((_vm.eventsComputed),function(event,index){return _c('TimeLineItem',{key:index,attrs:{"dateText":_vm.getDateText(event.date),"events":[event.events],"showDay":_vm.showDay(index),"color":event.color,"eventDate":_vm.getDayText(event.date),"filters":_vm.timelineFilters}})}),1):_vm._e(),(_vm.loadingTimeline)?_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"width":"100%","height":"100%"}},[_c('Loader',{attrs:{"size":150}})],1):_vm._e()])],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-col',{staticClass:"d-flex pa-3 align-end",style:({
          'background-color':
            _vm.selectedTab == 3
              ? _vm.$vuetify.theme.dark
                ? '#505253'
                : '#FAFAFA'
              : null,
        }),attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"elevation-0",attrs:{"color":"primary","rounded":"","block":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("Close")))])])],1):_vm._e()],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }