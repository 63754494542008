<template>
  <FormBasicLayout
    size="xl"
    class="pa-3"
    :disabled="loading.update"
    :showActions="true"
    style="transition: height 500ms ease"
    @back="close()"
    @continue="updateAvatar()"
  >
    <template #form>
      <v-row
        no-gutters
        style="max-height: 100%; height: 100%"
        class="pa-3 justify-space-between avatar-setting"
      >
        <span>
          <v-card-title
            class="font-weight-bold mb-1"
            style="color: var(--v-primary-base)"
            :class="$vuetify.breakpoint.lgAndDown ? 'text-h5' : 'text-h4'"
            cols="1"
          >
            {{ AddUserInfo().name }}
          </v-card-title>
          <v-card-subtitle
            style="letter-spacing: 1px !important"
            :class="
              $vuetify.breakpoint.lgAndDown ? 'text-subtitle-1' : 'text-h5'
            "
            :style="
              $vuetify.theme.dark ? { color: '#ffffff' } : { color: '#505253' }
            "
            class="font-weight-bold"
            >{{ AddUserInfo().message }}</v-card-subtitle
          >
        </span>
        <!--Skin color-->
        <v-col
          :cols="image ? 8 : 4"
          class="d-flex"
          :class="image ? 'justify-start' : 'justify-center'"
          :style="
            ($vuetify.breakpoint.mdAndDown
              ? { height: '250px !important' }
              : $vuetify.breakpoint.lgAndDown
              ? { height: '340px !important' }
              : { height: '400px !important' },
            image ? { paddingLeft: '42px' } : { paddingLeft: '85px' })
          "
        >
          <v-badge
            class="customBadge avatarBadge"
            :class="image ? 'hide' : ''"
            offset-x="105"
            offset-y="35"
            :color="image ? 'transparent' : 'success'"
            bordered
            bottom
            overlap
          >
            <v-hover
              v-slot="{ hover }"
              style="border-radius: 50%; overflow: hidden"
              :style="
                $vuetify.theme.dark
                  ? 'background-color: var(--v-background-accent) !important'
                  : ''
              "
            >
              <div
                @dragover.prevent="isUpload = true"
                @dragleave.prevent="isUpload = false"
                @drop.prevent="
                  uploadFile($event.dataTransfer.files), (isUpload = false)
                "
              >
                <v-avatar
                  id="downloadAvatar"
                  style="overflow: hidden; width: 38vh; height: 38vh"
                  :style="[
                    {
                      border: $vuetify.theme.dark
                        ? 'solid thin rgba(255, 255, 255, 0.12)'
                        : 'solid thin rgba(0, 0, 0, 0.12)',
                    },
                  ]"
                >
                  <v-img
                    v-show="image"
                    class="avatarImage"
                    :src="previewImage"
                    ref="avatarImage"
                    style="border-radius: 50%"
                  />

                  <avataaars
                    v-show="!image"
                    :isCircle="false"
                    :circleColor="$vuetify.theme.themes.light.primary"
                    :accessoriesType="avatar.accessoriesType"
                    :clotheType="avatar.clotheType"
                    :clotheColor="avatar.clotheColor"
                    :eyebrowType="avatar.eyebrowType"
                    :eyeType="avatar.eyeType"
                    :facialHairColor="avatar.facialHairColor"
                    :facialHairType="avatar.facialHairType"
                    :hairColor="avatar.hairColor"
                    :mouthType="avatar.mouthType"
                    :skinColor="avatar.skinColor"
                    :topType="avatar.topType"
                    :topColor="avatar.topColor"
                  />
                </v-avatar>
                <v-fade-transition>
                  <v-overlay
                    v-if="(hover && !image) || isUpload"
                    class="overlay-background"
                    @click="$refs.filePicker.click()"
                    absolute
                    style="cursor: pointer; height: 38vh; width: 38vh"
                    color="black"
                  >
                    <v-icon size="65px">mdi-upload</v-icon>
                  </v-overlay>
                </v-fade-transition>
                <v-fade-transition v-if="hover">
                  <v-overlay
                    v-if="(hover && image) || isUpload"
                    class="overlay-background"
                    absolute
                    style="cursor: pointer; height: 38vh; width: 38vh"
                    color="black"
                  >
                    <v-icon
                      style="margin-right: 20px"
                      @click="$refs.filePicker.click()"
                      size="70px"
                      >mdi-upload</v-icon
                    >
                    <v-icon
                      style="margin-left: 20px"
                      @click="deleteImage()"
                      size="70px"
                      >mdi-delete-outline</v-icon
                    >
                  </v-overlay>
                </v-fade-transition>
              </div>
            </v-hover>
          </v-badge>
          <!-- Add image -->
          <input
            type="file"
            ref="filePicker"
            accept="image/png, image/jpeg"
            style="display: none"
            @change="uploadFile($event.target.files)"
          />
        </v-col>
        <!-- Pickers items-->
        <v-col
          cols="3"
          style="border-radius: 10px; height: 63%"
          :style="{
            fontSize: $vuetify.breakpoint.mdAndDown
              ? '0.5rem'
              : $vuetify.breakpoint.lgAndDown
              ? '0.8rem'
              : '1.2rem',
            backgroundColor: $vuetify.theme.dark
              ? 'var(--v-background-accent) !important'
              : '',
          }"
          v-show="!image"
        >
          <v-row
            no-gutters
            style="
              overflow: hidden;
              max-height: 100%;
              height: 100%;
              padding-block: 10px;
            "
          >
            <v-col
              cols="12"
              v-for="(picker, i) in itemsPickers"
              :key="i"
              class="d-flex align-center"
            >
              <v-row
                no-gutters
                class="align-content-center justify-space-around itemPicker"
                style="min-height: 30px; position: relative"
                :style="{
                  color: $vuetify.theme.dark ? '#fff' : 'rgba(0, 0, 0, 0.87)',
                }"
                :class="{
                  active:
                    lastPicker ===
                    picker.lastPicker(
                      picker.items.findIndex(e => e === avatar[picker.value])
                    ),
                }"
                @click="
                  lastPicker = picker.lastPicker(
                    picker.items.findIndex(e => e === avatar[picker.value])
                  )
                "
              >
                <v-col cols="7" class="d-flex">
                  <ScrollArrows
                    style="width: 100%"
                    :style="
                      $vuetify.theme.dark
                        ? { color: '#ffffff' }
                        : { color: '#505253' }
                    "
                    :scroll="64"
                    @left="
                      moveLeft(picker.value, picker.text),
                        (lastPicker = picker.lastPicker(
                          picker.items.findIndex(
                            e => e === avatar[picker.value]
                          )
                        ))
                    "
                    @right="
                      moveRight(picker.value, picker.text),
                        (lastPicker = picker.lastPicker(
                          picker.items.findIndex(
                            e => e === avatar[picker.value]
                          )
                        ))
                    "
                  >
                    <template #content
                      ><v-subheader
                        style="height: auto"
                        class="pa-0"
                        :style="{
                          color:
                            lastPicker ===
                            picker.lastPicker(
                              picker.items.findIndex(
                                e => e === avatar[picker.value]
                              )
                            )
                              ? 'var(--v-primary-base)'
                              : $vuetify.theme.dark
                              ? '#fff'
                              : '#505253',
                          'font-weight':
                            lastPicker ===
                            picker.lastPicker(
                              picker.items.findIndex(
                                e => e === avatar[picker.value]
                              )
                            )
                              ? 'bold'
                              : '',
                          'font-size': $vuetify.breakpoint.lgAndDown
                            ? '0.8rem'
                            : '1.1rem',
                        }"
                        >{{ $i18n.t(picker.text) }}</v-subheader
                      >
                    </template>
                  </ScrollArrows>
                </v-col>
                <v-col cols="3" class="d-flex justify-center align-center">
                  <span
                    class="d-inline-block px-2"
                    v-for="(item, index) in picker.items.filter(
                      i => avatar[picker.value] === i
                    )"
                    :key="`${picker.value}-${index}`"
                  >
                    {{
                      picker.items.findIndex(e => e === avatar[picker.value]) +
                      1
                    }}
                    / {{ picker.items.length }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-row class="d-flex justify-center align-center">
              <v-btn
                icon
                class="d-flex font-weight-bold ma-1 align-center mt-2"
                style="
                  z-index: 1;
                  background-color: var(--v-primary-base);
                  width: min-content;
                  color: white;
                  border-radius: 2rem;
                "
                :style="
                  $vuetify.breakpoint.lgAndDown
                    ? {
                        padding: '0.3rem 0.8rem',
                        fontSize: '0.6rem',
                        height: '24px',
                      }
                    : {
                        padding: ' 0.6rem 1rem',
                        fontSize: '0.8rem',
                        height: '32px',
                      }
                "
                @click="avatar = getRandomAvatar()"
                >RANDOM
                <v-icon
                  :style="
                    $vuetify.breakpoint.mdAndDown
                      ? {
                          fontSize: '10px !important',
                        }
                      : $vuetify.breakpoint.lgAndDown
                      ? {
                          fontSize: '14px !important',
                        }
                      : {
                          fontSize: '24px !important',
                        }
                  "
                  color="white"
                >
                  mdi-dice-5
                </v-icon>
              </v-btn>
            </v-row>
          </v-row>
        </v-col>

        <div
          style="width: 100%"
          :style="$vuetify.breakpoint.lgAndDown ? 'gap: 20px' : 'gap: 30px'"
          class="d-flex flex-column justify-end overlay-background"
        >
          <v-col
            cols="12"
            v-show="!image"
            class="d-flex flex-column justify-end pa-0 ga-1"
            style="flex: none !important"
            :style="{
              height: $vuetify.breakpoint.mdAndDown
                ? '30px'
                : $vuetify.breakpoint.lgAndDown
                ? '70px'
                : '100px',
            }"
            :class="itemColor[lastPicker] == 'Disabled' ? 'disabledColor' : ''"
          >
            <v-row
              no-gutters
              class="d-flex justify-center"
              style="max-height: 100%; overflow: auto; border-radius: 20px"
              :style="
                $vuetify.theme.dark
                  ? 'background-color: var(--v-background-accent) !important'
                  : ''
              "
            >
              <v-col
                :cols="
                  colorsPickers.find(e => e.text === itemColor[lastPicker])
                    .items.length < 12
                    ? colorsPickers.find(e => e.text === itemColor[lastPicker])
                        .items.length - 3
                    : 11
                "
                v-for="(picker, i) in colorsPickers.filter(
                  p => p.text === itemColor[lastPicker]
                )"
                :key="i"
              >
                <ScrollArrows
                  :scroll="64"
                  :style="
                    itemColor[lastPicker] == 'Disabled'
                      ? 'color: #bbbbbb !important'
                      : $vuetify.theme.dark
                      ? 'color: #ffffff !important'
                      : 'color: #505253 !important'
                  "
                  @left="moveLeft(picker.value, picker.text)"
                  @right="moveRight(picker.value, picker.text)"
                >
                  <template #content>
                    <div
                      class="d-inline-block px-2 py-2"
                      v-for="(item, index) in reorderItems(picker)"
                      :key="`${picker.value}-${index}`"
                    >
                      <v-hover #default="{ hover }">
                        <v-card
                          class="pa-1"
                          flat
                          outlined
                          :height="
                            $vuetify.breakpoint.mdAndDown
                              ? '30px'
                              : $vuetify.breakpoint.lgAndDown
                              ? '40px'
                              : '60px'
                          "
                          :width="
                            $vuetify.breakpoint.mdAndDown
                              ? '30px'
                              : $vuetify.breakpoint.lgAndDown
                              ? '40px'
                              : '60px'
                          "
                          style="border-radius: 2rem; z-index: 1"
                          :style="[
                            {
                              'background-color': picker.getColor(item)
                                ? picker.getColor(item)
                                : 'rgb(211 208 206)',
                              transform:
                                avatar[picker.value] === item
                                  ? 'scale(1.1)'
                                  : null,
                            },
                          ]"
                          @click="avatar[picker.value] = item"
                        >
                          <v-fade-transition
                            style="position: absolute"
                            v-if="hover || avatar[picker.value] === item"
                          >
                            <v-overlay
                              style="position: absolute; transform: scale(1.2)"
                              class="color-overlay"
                            >
                              <!-- <v-icon large  :style="
                    $vuetify.breakpoint.mdAndDown
                      ? {
                          fontSize: '10px !important',
                        }
                      : $vuetify.breakpoint.lgAndDown
                      ? {
                          fontSize: '14px !important',
                        }
                      : {
                          fontSize: '24px !important',
                        }
                  ">mdi-check</v-icon> -->
                            </v-overlay>
                          </v-fade-transition>
                        </v-card>
                      </v-hover>
                    </div>
                  </template>
                </ScrollArrows>
              </v-col>
            </v-row>
          </v-col>

          <div
            style="max-height: 110px; border-radius: 20px"
            :style="{
              height: $vuetify.breakpoint.mdAndDown
                ? '30px'
                : $vuetify.breakpoint.lgAndDown
                ? '70px'
                : '100px',
              backgroundColor: $vuetify.theme.dark
                ? 'var(--v-background-accent) !important'
                : '',
            }"
            cols="12"
            class="d-flex justify-center"
          >
            <v-col
              :cols="skinColorPicker.items.length"
              v-show="!image"
              class="d-inline-flex justify-center pa-0 px-2"
            >
              <ScrollArrows
                :style="
                  $vuetify.theme.dark
                    ? { color: '#ffffff' }
                    : { color: '#505253' }
                "
                :scroll="64"
                @left="moveLeft(skinColorPicker.value, skinColorPicker.text)"
                @right="moveRight(skinColorPicker.value, skinColorPicker.text)"
              >
                <template #content>
                  <div
                    v-for="(item, index) in showSkinColorPicker"
                    class="d-inline-block pa-2"
                    :key="`${skinColorPicker.value}-${index}`"
                  >
                    <v-btn
                      @click="avatar[skinColorPicker.value] = item"
                      :height="
                        $vuetify.breakpoint.mdAndDown
                          ? '30px'
                          : $vuetify.breakpoint.lgAndDown
                          ? '40px'
                          : '60px'
                      "
                      :width="
                        $vuetify.breakpoint.mdAndDown
                          ? '30px'
                          : $vuetify.breakpoint.lgAndDown
                          ? '40px'
                          : '60px'
                      "
                      style="border-radius: 50%"
                      fab
                      small
                      elevation="0"
                      :style="{
                        'border-bottom':
                          avatar[skinColorPicker.value] === item
                            ? `solid ${skinColorPicker.getColor(item)} 2px`
                            : 'none',
                        'background-color':
                          avatar[skinColorPicker.value] === item
                            ? '#B6B8B9'
                            : 'transparent',
                        transform:
                          avatar[skinColorPicker.value] === item
                            ? 'scale(1.2)'
                            : '',
                      }"
                    >
                      <v-icon
                        :color="skinColorPicker.getColor(item)"
                        large
                        class="pa-1"
                        :style="
                          $vuetify.breakpoint.mdAndDown
                            ? {
                                fontSize: '20px !important',
                              }
                            : $vuetify.breakpoint.lgAndDown
                            ? {
                                fontSize: '24px !important',
                              }
                            : {
                                fontSize: '40px !important',
                              }
                        "
                      >
                        mdi-account
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
              </ScrollArrows>
            </v-col>
          </div>
        </div>
        <!--Colors-->
      </v-row>

      <v-dialog
        v-model="dialogs.delete"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        max-width="400px"
      >
        <ConfirmForm
          :title="'Delete avatar'"
          type="error"
          :loading="loading.deleteAvatar"
          @back="dialogs.delete = false"
          @continue="deleteAvatar()"
        />
      </v-dialog>
    </template>
  </FormBasicLayout>
</template>

<script>
import Avataaars from "vuejs-avataaars";
import ScrollArrows from "@/components/base/ScrollArrows";

import domtoimage from "dom-to-image-more";
import User from "@/controllers/user.js";

import { useAppStore, useInboxStore, validateImage } from "@/utils";

export default {
  name: "AvatarForm",

  components: {
    Avataaars,
    ScrollArrows,
  },

  props: {
    data: { type: Object },
    isUser: { type: Boolean },
  },

  data() {
    return {
      dialogs: { delete: false },
      loading: { update: false, deleteAvatar: false },
      image: false,
      isUpload: false,
      avatar: null,
      previewImage: "",
      lastPicker: "Facial hair",
      itemColor: {
        Accessory: "Accessory color",
        Hairstyle: "Hair color",
        "Facial hair": "Facial hair color",
        Outfit: "Outfit color",
        Glasses: "Disabled",
        Eyebrows: "Disabled",
        Eyes: "Disabled",
        Mouth: "Disabled",
      },
      itemsPickers: [
        {
          text: "Top",
          value: "topType",
          lastPicker: index => (index > 26 ? "Accessory" : "Hairstyle"),
          type: "avatar",
          show: () => {
            return true;
          },
          items: [
            "NoHair",
            "LongHairBigHair",
            "LongHairBob",
            "LongHairBun",
            "LongHairCurly",
            "LongHairCurvy",
            "LongHairDreads",
            "LongHairFrida",
            "LongHairFro",
            "LongHairFroBand",
            "LongHairNotTooLong",
            "LongHairShavedSides",
            "LongHairMiaWallace",
            "LongHairStraight",
            "LongHairStraight2",
            "LongHairStraightStrand",
            "ShortHairDreads01",
            "ShortHairDreads02",
            "ShortHairFrizzle",
            "ShortHairShaggyMullet",
            "ShortHairShortCurly",
            "ShortHairShortFlat",
            "ShortHairShortRound",
            "ShortHairShortWaved",
            "ShortHairSides",
            "ShortHairTheCaesar",
            "ShortHairTheCaesarSidePart",
            "Eyepatch",
            "Hat",
            "Hijab",
            "Turban",
            "WinterHat1",
            "WinterHat2",
            "WinterHat3",
            "WinterHat4",
          ],
        },
        {
          text: "Facial hair",
          value: "facialHairType",
          lastPicker: () => "Facial hair",
          type: "avatar",
          show: () => {
            return true;
          },
          items: [
            "Blank",
            "BeardMedium",
            "BeardLight",
            "BeardMagestic",
            "MoustacheFancy",
            "MoustacheMagnum",
          ],
        },
        {
          text: "Glasses",
          value: "accessoriesType",
          lastPicker: () => "Glasses",
          type: "avatar",
          show: () => {
            return true;
          },
          items: ["Blank", "Prescription01", "Prescription02", "Round"],
        },
        {
          text: "Eyebrows",
          value: "eyebrowType",
          lastPicker: () => "Eyebrows",
          type: "avatar",
          show: () => {
            return true;
          },
          items: [
            "Angry",
            "AngryNatural",
            "Default",
            "DefaultNatural",
            "FlatNatural",
            "RaisedExcited",
            "RaisedExcitedNatural",
            "SadConcerned",
            "SadConcernedNatural",
            "UnibrowNatural",
            "UpDown",
            "UpDownNatural",
          ],
          getColor: value => {
            return this.getAccessoryColor(value);
          },
        },
        {
          text: "Eyes",
          value: "eyeType",
          lastPicker: () => "Eyes",
          type: "avatar",
          show: () => {
            return true;
          },
          items: [
            "Close",
            "Cry",
            "Default",
            "Dizzy",
            "EyeRoll",
            "Happy",
            "Hearts",
            "Side",
            "Squint",
            "Surprised",
            "Wink",
            "WinkWacky",
          ],
        },
        {
          text: "Mouth",
          value: "mouthType",
          lastPicker: () => "Mouth",
          type: "avatar",
          show: () => {
            return true;
          },
          items: [
            "Concerned",
            "Default",
            "Disbelief",
            "Eating",
            "Grimace",
            "Sad",
            "ScreamOpen",
            "Serious",
            "Smile",
            "Tongue",
            "Twinkle",
          ],
        },
        {
          text: "Outfit",
          value: "clotheType",
          lastPicker: () => "Outfit",
          type: "avatar",
          show: () => {
            return true;
          },
          items: [
            "BlazerShirt",
            "BlazerSweater",
            "CollarSweater",
            "Hoodie",
            "Overall",
            "ShirtCrewNeck",
            "ShirtScoopNeck",
            "ShirtVNeck",
          ],
        },
      ],
      colorsPickers: [
        {
          text: "Hair color",
          value: "hairColor",
          type: "color",
          show: () => {
            return !this.itemsPickers
              .find(e => e.text === "Accessory")
              .items.includes(this.avatar.topType);
          },
          items: [
            "Auburn",
            "Black",
            "Blonde",
            "BlondeGolden",
            "Brown",
            "BrownDark",
            "PastelPink",
            "Platinum",
            "Red",
            "SilverGray",
          ],
          getColor: value => {
            return this.getHairColor(value);
          },
        },
        {
          text: "Disabled",
          disabled: true,
          value: "disabledColor",
          type: "color",
          show: () => {
            return !this.itemsPickers
              .find(e => e.text === "Accessory")
              .items.includes(this.avatar.topType);
          },
          items: [
            "Auburn",
            "Black",
            "Blonde",
            "BlondeGolden",
            "Brown",
            "BrownDark",
            "PastelPink",
            "Platinum",
            "Red",
            "PastelBlue",
            "PastelOrange",
          ],
          getColor: value => {
            return this.getDisabledColor(value);
          },
        },
        {
          text: "Accessory color",
          value: "topColor",
          type: "color",
          show: () => {
            return !this.itemsPickers
              .find(e => e.text === "Hairstyle")
              .items.includes(this.avatar.topType);
          },
          items: [
            "Black",
            "Blue01",
            "Blue02",
            "Blue03",
            "Gray01",
            "Gray02",
            "Heather",
            "PastelBlue",
            "PastelGreen",
            "PastelOrange",
            "PastelRed",
            "PastelYellow",
            "Pink",
            "Red",
            "White",
          ],
          getColor: value => {
            return this.getAccessoryColor(value);
          },
        },
        {
          text: "Facial hair color",
          value: "facialHairColor",
          type: "color",
          show: () => {
            return true;
          },
          items: [
            "Auburn",
            "Black",
            "Blonde",
            "BlondeGolden",
            "Brown",
            "BrownDark",
            "PastelPink",
            "Platinum",
            "Red",
            "SilverGray",
          ],
          getColor: value => {
            return this.getHairColor(value);
          },
        },
        {
          text: "Outfit color",
          value: "clotheColor",
          type: "color",
          show: () => {
            return true;
          },
          items: [
            "Black",
            "Blue01",
            "Blue02",
            "Blue03",
            "Gray01",
            "Gray02",
            "Heather",
            "PastelBlue",
            "PastelGreen",
            "PastelOrange",
            "PastelRed",
            "PastelYellow",
            "Pink",
            "Red",
            "White",
          ],
          getColor: value => {
            return this.getAccessoryColor(value);
          },
        },
      ],
      skinColorPicker: {
        text: "Skin color",
        value: "skinColor",
        type: "color",
        show: () => {
          return true;
        },
        items: [
          "Tanned",
          "Yellow",
          "Pale",
          "Light",
          "Brown",
          "DarkBrown",
          "Black",
        ],
        getColor: value => {
          return this.getSkinColor(value);
        },
      },
      dateForAvatarImage: 0,
      noAvatarImage: false,
    };
  },

  created() {
    if (this.$props.data.avatar.replace('"', "").length <= 0)
      this.$props.data.avatar = "";
    this.getAvatarUserInfo();
    this.dateForAvatarImage = Date.now();
  },

  methods: {
    AddUserInfo() {
      let isAgent =
        useAppStore().user.name == this.$props.data.name
          ? {
              name: this.$i18n.t("Hi", {
                name: this.$props.data.name,
              }),
              message: this.$i18n.t("Create your avatar"),
            }
          : {
              name: this.$i18n.t("Hi", {
                name: useAppStore().user.name,
              }),
              message: this.$i18n.t("CreateAgentAvatar", {
                name: this.$props.data.name,
              }),
            };

      return isAgent;
    },
    getAvatarProps(property, value) {
      let avatar = JSON.parse(JSON.stringify(this.avatar));
      avatar[property] = value;

      return avatar;
    },

    close() {
      let avatarAux = this.$props.data.avatar.startsWith(`"`)
        ? this.$props.data.avatar.slice(1, -1)
        : this.$props.data.avatar;

      if (avatarAux || avatarAux != "") {
        this.avatar = JSON.parse(avatarAux);
      }

      this.dateForAvatarImage = Date.now();
      this.previewImage = "";

      this.$emit("close");
    },

    async updateAvatar() {
      if (!this.$props.isUser) {
        let result = null;
        let file = this.previewImage.includes("noAvatar.svg")
          ? this.previewImage
          : !this.image
          ? await domtoimage.toPng(document.getElementById("downloadAvatar"))
          : this.$refs.filePicker.files[0];

        if (!this.image) {
          await fetch(file)
            .then(res => res.blob())
            .then(res => (file = res));
        }

        let object = null;
        if (this.previewImage.includes("noAvatar.svg")) {
          result =
            useAppStore().user.username !== this.data.username
              ? await useInboxStore().changeAvatarUser(file, "")
              : await User.changeMyAvatar(
                  file,
                  {
                    ...this.avatar,
                    image: this.image,
                  },
                  this.data,
                  true
                );
          object = "";
        } else {
          result =
            useAppStore().user.username !== this.data.username
              ? await User.changeAvatarUser(
                  file,
                  {
                    ...this.avatar,
                    image: this.image,
                  },
                  this.data
                )
              : await User.changeMyAvatar(
                  file,
                  {
                    ...this.avatar,
                    image: this.image,
                  },
                  this.data
                );
          object = { ...this.avatar, image: this.image };
        }

        if (result) {
          this.$notify({
            group: "app",
            duration: 2000,
            text: "Updated avatar successfully",
          });
          useAppStore().updateAvatar(object);
          this.close();
        } else {
          this.$notify({
            group: "app",
            duration: 2000,
            text: "Error",
          });
        }
      } else {
        let file = this.previewImage.includes("noAvatar.svg")
          ? this.previewImage
          : !this.image
          ? await domtoimage.toPng(document.getElementById("downloadAvatar"))
          : this.$refs.filePicker.files[0];

        if (!this.image) {
          await fetch(file)
            .then(res => res.blob())
            .then(res => (file = res));
        }

        let object = this.previewImage.includes("noAvatar.svg")
          ? {
              avatarFile: file,
              avatar: this.avatar,
              defaulAvatar: true,
            }
          : { avatarFile: file, avatar: this.avatar };

        this.$emit("changeAvatar", object);
      }
    },

    getRandomAvatar() {
      let avatar = {};

      this.itemsPickers
        .concat(this.colorsPickers, this.skinColorPicker)
        .forEach(e => {
          if (e.text !== "Accessory") {
            avatar[e.value] =
              e.items[Math.floor(Math.random() * e.items.length)];
          }
        });

      return avatar;
    },

    uploadFile(files) {
      if (files.length) {
        if (!validateImage(files[0])) {
          this.$notify({
            group: "app",
            duration: 2000,
            text: "Invalid image",
          });

          return;
        }
        this.image = true;
        this.previewImage = URL.createObjectURL(files[0]);
      }
    },

    getSkinColor(value) {
      var colors = {
        Tanned: "#FD9841",
        Yellow: "#F8D25C",
        Pale: "#FFDBB4",
        Light: "#EDB98A",
        Brown: "#D08B5B",
        DarkBrown: "#AE5D29",
        Black: "#614335",
      };

      return colors[value];
    },

    getDisabledColor(value) {
      var colors = {
        Auburn: "rgb(211 208 206)",
        Black: "rgb(211 208 206)",
        Blonde: "rgb(211 208 206)",
        BlondeGolden: "rgb(211 208 206)",
        Brown: "rgb(211 208 206)",
        BrownDark: "rgb(211 208 206)",
        PastelPink: "rgb(211 208 206)",
        Platinum: "rgb(211 208 206)",
        Red: "rgb(211 208 206)",
        PastelBlue: "rgb(211 208 206)",
      };

      return colors[value];
    },

    getHairColor(value) {
      var colors = {
        Auburn: "#A55728",
        Black: "#2C1B18",
        Blonde: "#B58143",
        BlondeGolden: "#D6B370",
        Brown: "#724133",
        BrownDark: "#4A312C",
        PastelPink: "#F59797",
        Platinum: "#ECDCBF",
        Red: "#C93305",
        SilverGray: "#E8E1E1",
      };

      return colors[value];
    },

    getAccessoryColor(value) {
      var colors = {
        Black: "#262E33",
        Blue01: "#65C9FF",
        Blue02: "#5199E4",
        Blue03: "#25557C",
        Gray01: "#E6E6E6",
        Gray02: "#929598",
        Heather: "#3C4F5C",
        PastelBlue: "#B1E2FF",
        PastelGreen: "#A7FFC4",
        PastelOrange: "#FFBC69",
        PastelRed: "#FFAFB9",
        PastelYellow: "#FFFFB1",
        Pink: "#FF488E",
        Red: "#FF5C5C",
        White: "#FFFFFF",
      };

      return colors[value];
    },

    avatarUrl(username) {
      return `/attachments/avatars/${username}.png?${this.dateForAvatarImage}`;
    },

    getAvatarUserInfo() {
      this.$props.data.avatar = this.$props.data.avatar.replace("'", "");

      if (this.$props.data.avatar || this.$props.data.avatar != "") {
        let avatarAux = this.$props.data.avatar.startsWith(`"`)
          ? this.$props.data.avatar.slice(1, -1)
          : this.$props.data.avatar;

        let avatarObj = JSON.parse(avatarAux);

        if (avatarObj.image) {
          this.image = true;
          this.previewImage = this.avatarUrl(this.$props.data.username);
        }
        this.avatar = avatarObj;
      } else {
        this.avatar = this.getRandomAvatar();
      }
    },

    cancelAvatar() {
      if (!this.image) {
        this.dialogs.delete = true;
      } else {
        this.image = false;
        this.previewImage = "";
      }
    },

    async deleteAvatar() {
      this.loading.deleteAvatar = true;
      this.previewImage = "/images/noAvatar.svg";
      await this.updateAvatar();
      this.loading.deleteAvatar = false;
    },

    moveLeft(property, text) {
      let index = this.itemsPickers
        .concat(this.colorsPickers, this.skinColorPicker)
        .findIndex(e => e.text === text);
      let picker = this.itemsPickers.concat(
        this.colorsPickers,
        this.skinColorPicker
      )[index];

      let i = picker.items.findIndex(e => e === this.avatar[property]);
      if (i > 0) {
        this.avatar[property] = picker.items[i - 1];
      } else {
        this.avatar[property] = picker.items[picker.items.length - 1];
      }
    },

    moveRight(property, text) {
      let index = this.itemsPickers
        .concat(this.colorsPickers, this.skinColorPicker)
        .findIndex(e => e.text === text);
      let picker = this.itemsPickers.concat(
        this.colorsPickers,
        this.skinColorPicker
      )[index];

      let i = picker.items.findIndex(e => e === this.avatar[property]);
      if (i < picker.items.length - 1) {
        this.avatar[property] = picker.items[i + 1];
      } else {
        this.avatar[property] = picker.items[0];
      }
    },

    reorderItems(picker) {
      let index = picker.items.findIndex(e => e === this.avatar[picker.value]);
      let half = Math.floor(picker.items.length / 2);
      let items = [];

      for (let i = 0; i < half; i++) {
        items.push(
          picker.items[
            index - half + i < 0
              ? picker.items.length + (index - half + i)
              : index - half + i
          ]
        );
      }

      items.push(picker.items[index]);

      for (let i = 0; i < half; i++) {
        items.push(
          picker.items[
            index + i + 1 > picker.items.length - 1
              ? index + i + 1 - picker.items.length
              : index + i + 1
          ]
        );
      }

      return [...new Set(items)];
    },

    async deleteImage() {
      this.$notify({
        group: "app",
        duration: 2000,
        text: "Delete image successfully",
      });

      this.image = false;
    },
  },

  computed: {
    showSkinColorPicker() {
      const items = this.skinColorPicker.items;
      const index = this.avatar.skinColor
        ? items.findIndex(e => e === this.avatar.skinColor)
        : 0;

      return [
        items.at(index - 2), //before before
        items.at(index - 1), //before
        items.at(index), //selected
        items.at(index + 1 === items.length ? 0 : index + 1), //after
        items.at(index + 2 === items.length ? 0 : index + 2), //after after
      ];
    },
  },
};
</script>

<style lang="scss">
.avatarBadge:hover {
  filter: blur(1px);
}

.customBadgeSmall .v-badge__badge,
.avatar-setting .v-badge__badge {
  display: none;
}

.customBadge .v-badge__badge {
  border-radius: 50%;
  height: 37px;
  width: 37px;
  padding: 0px;
  border: 1px solid var(--v-success-base) !important;
}

.customBadge.hide .v-badge__badge {
  opacity: 0;
}

.avatarImage .v-image__image--cover {
  border-radius: 50%;
}

.overlay-background .v-overlay--active > .v-overlay__scrim {
  border: 5px solid #b6b8b9 !important;
  background-color: transparent !important;
}

.itemPicker {
  cursor: pointer;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.itemPicker.active {
  color: var(--v-primary-base) !important;
  font-weight: bold;
  z-index: 1;
}

.disabledColor {
  opacity: 13px;
  pointer-events: none;
  cursor: none;
  filter: brightness(95%);
}

.disabledColor .v-overlay--active > .v-overlay__scrim,
.disabledColor .color-overlay {
  border: 0px !important;
  background-color: transparent !important;
  transform: scale(0) !important;
  pointer-events: none;
  cursor: auto;
}
</style>
