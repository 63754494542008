import { useAxios, useWallboardStore, luxon } from "@/utils";

export default class Stats {
  constructor() {
    this.requestInProgress = false;
    this.currentRequest = null;
    this.lastRequest = null;
  }

  async executeRequest(callback) {
    this.requestInProgress = true;
    try {
      await callback();
    } catch (error) {
      console.log(error);
    } finally {
      this.requestInProgress = false;
      if (this.lastRequest && this.lastRequest !== this.currentRequest) {
        const nextRequest = this.lastRequest;
        this.lastRequest = null;
        this.executeRequest(nextRequest);
      }
    }
  }

  async requestToDo(callback) {
    if (typeof callback !== "function") {
      return;
    }

    if (this.requestInProgress) {
      this.lastRequest = callback;
    } else {
      this.currentRequest = callback;
      await this.executeRequest(callback);
    }
  }

  static async getStats(wallboardName, chart, filters) {
    const params = {
      ...this.getQueryParams(wallboardName, chart, filters),
      onlyRequested: true,
    };
    const resources = JSON.parse(JSON.stringify(params)).resources;
    delete params.resources;
    let res = await useAxios({
      method: "POST",
      url: "/api/stats",
      params,
      headers: {},
      data: { stats: resources },
    });
    if (res.success) {
      let response = res.res.data.result;
      for (let elem of response) {
        let parsedFormat = luxon()
          .fromFormat(elem.date, "MMM d, yyyy h:mm:ss a")
          .toFormat("yyyy-MM-dd HH:mm:ss");
        elem.date = parsedFormat;
      }

      return res.res.data.result;
    } else {
      console.log(res);
    }
  }

  static getQueryParams(wallboardName, chart, filters) {
    let wallboard = useWallboardStore().getWallboard(wallboardName);
    let localFilters =
      wallboard.charts && wallboard.charts.find(f => f.lid === chart.lid)
        ? wallboard.charts.find(f => f.lid === chart.lid)?.filters
        : chart.filters;
    let globalFilters = filters;

    //Set dates
    let interval = globalFilters.interval || "15";
    let initialdate = this.getOffset(
      globalFilters.start,
      "yyyy-MM-dd HH:mm:ss"
    );
    let finaldate = this.getOffset(globalFilters.end, "yyyy-MM-dd HH:mm:ss");

    let byResource = chart.by === "resource";

    //If byResource is true, then byChannel is false
    let byChannel = chart.by === "channel";

    //Set resources and channels
    let resources = "";
    let channels = "";

    const source = `${chart.source}s`;
    resources =
      localFilters && localFilters[source] && localFilters[source].length
        ? localFilters[source].join(",")
        : globalFilters[source].join(",");

    if (chart.source === "campaign") {
      channels =
        localFilters && localFilters.channels && localFilters.channels.length
          ? localFilters.channels.join(",")
          : globalFilters.channels.join(",");
    }

    let metrics = chart.metrics.metrics.map(m => m.id).join(",");
    let customMetrics = chart.customMetrics?.metrics || "";

    return {
      groupnum: interval,
      initialdate,
      finaldate,
      type: chart.source,
      metrics: metrics,
      resources,
      channels,
      byResource,
      byChannel: byChannel,
      customMetrics: JSON.stringify(customMetrics),
      total: ["PieChart", "DoughnutChart", "BarChart", "TotalChart"].includes(
        chart.type
      ),
    };
  }

  static async getStatsQuery(chart, filters, wallboardName) {
    let query = chart.query;
    query = query.replaceAll("${start}", `'${filters.start}'`);
    query = query.replaceAll("${end}", `'${filters.end}'`);
    query = query.replaceAll("${agents}", `('${filters.agents.join("','")}')`);
    query = query.replaceAll(
      "${campaigns}",
      `('${filters.campaigns.join("','")}')`
    );
    query = query.replaceAll(
      "${channels}",
      `('${filters.channels.join("','")}')`
    );

    //get searchs from wallboardStore
    let wallboard = useWallboardStore().getWallboard(wallboardName);
    let localFilters =
      wallboard.charts && wallboard.charts.find(f => f.lid === chart.lid)
        ? wallboard.charts.find(f => f.lid === chart.lid)?.filters
        : chart.filters;
    let { searchs } = localFilters || null;

    //eslint-disable-next-line no-useless-escape
    let searchsOnQuery = query.match(/(\$\{search[^\}]*\})/g) || [];

    //searchs is a object with key value
    for (let searchOnQuery of searchsOnQuery) {
      let key = searchOnQuery.replace(/\$\{|\}/g, "");
      query = query.replace(searchOnQuery, `${searchs?.[key] || null}`);
    }
    let result = {
      error: false,
      message: "",
      data: [],
    };
    let res = await useAxios({
      method: "GET",
      url: "/api/stats/query",
      params: {
        type: chart.source,
        query,
        interval: filters.interval || "15",
        initialdate: filters.start,
        finaldate: filters.end,
      },
      headers: {},
    });
    result.error = !res.success;
    if (res.success) {
      result.data = res.res.data.result;
    } else {
      console.log(res);

      if (res.res.response.status == 400) {
        result.message = res.res.response.data.param;
      } else {
        result.message = "Something went wrong";
      }
    }

    return result;
  }

  static async getStatsBySource(params) {
    const end = this.getOffset(params.end, "yyyy-MM-dd HH:mm:ss");
    const start = this.getOffset(params.start, "yyyy-MM-dd HH:mm:ss");
    let res = await useAxios({
      method: "POST",
      url: "/api/stats",
      params: {
        groupnum: params.interval,
        initialdate: start,
        finaldate: end,
        type: params.source,
        metrics: params.metrics,
        channels: params.channels,
        byResource: params.byResource,
        byChannel: params.byChannel,
        customMetrics: JSON.stringify(params.customMetrics),
        total: params.total,
        pendingDisposition: params.pendingDisposition,
      },
      headers: {},
      data: { stats: params.resources },
    });
    if (res.success) {
      let response = res.res.data.result;
      for (let elem of response) {
        let parsedFormat = luxon()
          .fromFormat(elem.date, "MMM d, yyyy h:mm:ss a")
          .toFormat("yyyy-MM-dd HH:mm:ss");
        elem.date = parsedFormat;
      }

      return res.res.data.result;
    } else {
      console.log(res);
    }
  }

  static async getAgentEvent(params) {
    const end = this.getOffset(params.end, "yyyy-MM-dd HH:mm:ss");
    const start = this.getOffset(params.start, "yyyy-MM-dd HH:mm:ss");

    let res = await useAxios({
      method: "GET",
      url: "/api/stats/agentEvents",
      params: {
        start: start,
        end: end,
        agent: params.agent,
        groupnum: params.interval,
      },
      headers: {},
    });
    if (res.success) {
      return res.res.data.result;
    } else {
      console.log(res);
    }
  }

  static getOffset(date, formatIn) {
    return luxon()
      .fromFormat(date, formatIn)
      .toFormat("yyyy-MM-dd HH:mm:ss ZZ");
  }
}
