<template>
  <v-card
    class="fill-height FormsBasicLayout"
    :class="[size, flex ? 'd-flex' : null]"
    :height="$vuetify.breakpoint.smAndDown ? null : '100%'"
    :max-height="$vuetify.breakpoint.smAndDown ? null : '100%'"
    :style="
      $vuetify.theme.dark
        ? 'background-color: var(--v-background-base) !important'
        : ''
    "
  >
    <v-row no-gutters class="align-start fill-height" style="overflow: hidden">
      <!-- Title -->
      <v-col
        cols="12"
        class="d-flex justify-center align-center"
        :class="$props.padding"
        style="position: relative"
        v-if="$props.title || $vuetify.breakpoint.smAndDown"
      >
        <v-card-title
          class="text-h5 text--primary pa-0"
          v-if="$props.title"
          style="color: var(--v-primary-base) !important; font-weight: bold"
        >
          {{ $i18n.t($props.title, $props.notTraductionTitle) }}
        </v-card-title>

        <v-btn
          v-if="!$props.showActions && $props.closeMobile"
          icon
          x-large
          class="mr-2"
          style="position: absolute; right: 0"
          @click="$emit('back')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>

      <!-- Action -->
      <v-col cols="12" :class="[{ 'px-6': $slots.action }]">
        <slot name="action" />
      </v-col>

      <v-col
        cols="12"
        class="fill-height"
        :style="[
          {
            'max-height': `calc(100%  ${
              $props.title ? ' - 80px' : '0px'
            }) !important`,
          },
        ]"
      >
        <v-card-text class="fill-height" :class="$props.padding">
          <!-- Form -->
          <div
            :class="overflowY ? null : 'overflow-y-auto'"
            :style="[
              {
                'max-height': $props.showActions
                  ? 'calc(100% - 52px) !important'
                  : '100%',
                height: $props.showActions
                  ? 'calc(100% - 52px) !important'
                  : '100%',
              },
            ]"
          >
            <slot name="form" />
          </div>

          <!-- Actions -->
          <v-card-actions v-if="$props.showActions">
            <v-row no-gutters>
              <v-col
                :cols="changeButtonSize"
                class="px-3"
                :offset="6 - changeButtonSize"
              >
                <v-btn
                  block
                  :outlined="$vuetify.theme.dark ? false : true"
                  rounded
                  class="elevation-0"
                  :class="
                    $vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-2'
                  "
                  @click="$emit('back')"
                >
                  {{ $i18n.t("Cancel") }}
                </v-btn>
              </v-col>
              <v-col :cols="changeButtonSize" class="px-3">
                <v-btn
                  class="elevation-0"
                  block
                  rounded
                  color="primary"
                  :loading="$props.loading"
                  :disabled="$props.disabled"
                  @click="$emit('continue')"
                >
                  {{
                    $i18n.t(
                      $props.saveButtonText ? $props.saveButtonText : "Save"
                    )
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "FormBasicLayout",

  props: {
    title: { type: String },
    height: { type: String },
    loading: { type: Boolean },
    disabled: { type: Boolean },
    showActions: { type: Boolean },
    overflowY: { type: Boolean, default: false },
    flex: { type: Boolean, default: false },
    notTraductionTitle: { type: Object, default: null },
    saveButtonText: { type: String, default: "Save" },
    size: {
      type: String,
      default: "md",
      validator: value => ["sm", "md", "lg", "xl"].includes(value),
    },
    padding: { type: String, default: "pa-6" },
    closeMobile: { type: Boolean, default: true },
  },
  computed: {
    changeButtonSize() {
      let validatorSize = ["sm", "md", "lg", "xl"];
      let buttonsSizes = [6, 6, 4, 4];
      let iArray = -1;

      validatorSize.forEach((e, index) => {
        if (e === this.size) {
          iArray = index;
        }
      });

      return buttonsSizes[iArray];
    },
  },
};
</script>
<style scoped>
.small {
  height: 400px;
}

.medium {
  height: 600px;
}

.large {
  height: 850px;
}
</style>

<style>
.v-dialog:has(.FormsBasicLayout.sm):not(.v-dialog--fullscreen) {
  height: 35% !important;
  width: auto;
  aspect-ratio: 16 / 9;
}
.v-dialog:has(.FormsBasicLayout.md):not(.v-dialog--fullscreen) {
  height: 50% !important;
  width: auto;

  aspect-ratio: 16 / 9;
}

.v-dialog:has(.FormsBasicLayout.lg):not(.v-dialog--fullscreen) {
  height: 65% !important;
  width: auto;

  aspect-ratio: 16 / 9;
}

.v-dialog:has(.FormsBasicLayout.xl):not(.v-dialog--fullscreen) {
  height: 90% !important;
  width: auto;

  aspect-ratio: 16 / 9;
}
</style>
