import { render, staticRenderFns } from "./FormBasicLayout.vue?vue&type=template&id=6fa9a8ea&scoped=true"
import script from "./FormBasicLayout.vue?vue&type=script&lang=js"
export * from "./FormBasicLayout.vue?vue&type=script&lang=js"
import style0 from "./FormBasicLayout.vue?vue&type=style&index=0&id=6fa9a8ea&prod&scoped=true&lang=css"
import style1 from "./FormBasicLayout.vue?vue&type=style&index=1&id=6fa9a8ea&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fa9a8ea",
  null
  
)

export default component.exports