var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height FormsBasicLayout",class:[_vm.size, _vm.flex ? 'd-flex' : null],style:(_vm.$vuetify.theme.dark
      ? 'background-color: var(--v-background-base) !important'
      : ''),attrs:{"height":_vm.$vuetify.breakpoint.smAndDown ? null : '100%',"max-height":_vm.$vuetify.breakpoint.smAndDown ? null : '100%'}},[_c('v-row',{staticClass:"align-start fill-height",staticStyle:{"overflow":"hidden"},attrs:{"no-gutters":""}},[(_vm.$props.title || _vm.$vuetify.breakpoint.smAndDown)?_c('v-col',{staticClass:"d-flex justify-center align-center",class:_vm.$props.padding,staticStyle:{"position":"relative"},attrs:{"cols":"12"}},[(_vm.$props.title)?_c('v-card-title',{staticClass:"text-h5 text--primary pa-0",staticStyle:{"color":"var(--v-primary-base) !important","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$i18n.t(_vm.$props.title, _vm.$props.notTraductionTitle))+" ")]):_vm._e(),(!_vm.$props.showActions && _vm.$props.closeMobile)?_c('v-btn',{staticClass:"mr-2",staticStyle:{"position":"absolute","right":"0"},attrs:{"icon":"","x-large":""},on:{"click":function($event){return _vm.$emit('back')}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1):_vm._e(),_c('v-col',{class:[{ 'px-6': _vm.$slots.action }],attrs:{"cols":"12"}},[_vm._t("action")],2),_c('v-col',{staticClass:"fill-height",style:([
        {
          'max-height': `calc(100%  ${
            _vm.$props.title ? ' - 80px' : '0px'
          }) !important`,
        },
      ]),attrs:{"cols":"12"}},[_c('v-card-text',{staticClass:"fill-height",class:_vm.$props.padding},[_c('div',{class:_vm.overflowY ? null : 'overflow-y-auto',style:([
            {
              'max-height': _vm.$props.showActions
                ? 'calc(100% - 52px) !important'
                : '100%',
              height: _vm.$props.showActions
                ? 'calc(100% - 52px) !important'
                : '100%',
            },
          ])},[_vm._t("form")],2),(_vm.$props.showActions)?_c('v-card-actions',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-3",attrs:{"cols":_vm.changeButtonSize,"offset":6 - _vm.changeButtonSize}},[_c('v-btn',{staticClass:"elevation-0",class:_vm.$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-2',attrs:{"block":"","outlined":_vm.$vuetify.theme.dark ? false : true,"rounded":""},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(" "+_vm._s(_vm.$i18n.t("Cancel"))+" ")])],1),_c('v-col',{staticClass:"px-3",attrs:{"cols":_vm.changeButtonSize}},[_c('v-btn',{staticClass:"elevation-0",attrs:{"block":"","rounded":"","color":"primary","loading":_vm.$props.loading,"disabled":_vm.$props.disabled},on:{"click":function($event){return _vm.$emit('continue')}}},[_vm._v(" "+_vm._s(_vm.$i18n.t( _vm.$props.saveButtonText ? _vm.$props.saveButtonText : "Save" ))+" ")])],1)],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }