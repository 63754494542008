var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height FormsTabsLayout",class:[_vm.size, _vm.flex ? 'd-flex' : null],attrs:{"max-height":_vm.$vuetify.breakpoint.smAndDown ? null : _vm.$props.height}},[_c('div',{staticClass:"fill-height d-flex",class:[{ 'flex-column': _vm.$vuetify.breakpoint.smAndDown }],staticStyle:{"width":"100%"}},[_c('div',{staticClass:"grayTabs d-flex flex-column",staticStyle:{"min-width":"30%"}},[_c('v-card-title',{staticClass:"text-h6 text--primary px-10",class:[{ 'justify-center': _vm.$vuetify.breakpoint.smAndDown }],staticStyle:{"color":"var(--v-primary-base) !important","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$i18n.t(_vm.$props.title))+" ")]),(!_vm.$props.showActions && _vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticClass:"mr-2",staticStyle:{"position":"absolute","top":"0","right":"0"},attrs:{"icon":"","x-large":""},on:{"click":function($event){return _vm.$emit('back')}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),_vm._t("tabs")],2),_c('div',{staticStyle:{"background-color":"var(--v-background-accent)","flex":"1","overflow":"hidden"}},[_c('v-card-text',{staticClass:"pa-5 fill-height"},[_c('div',{class:_vm.overflowY ? null : 'overflow-y-auto',style:([
            {
              height: _vm.$props.showActions
                ? 'calc(100% - 52px) !important'
                : '100%',
            },
          ])},[_vm._t("form")],2),(_vm.$props.showActions)?_c('v-card-actions',[_c('v-row',{staticClass:"d-flex justify-center",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-3",attrs:{"cols":_vm.changeButtonSize}},[_c('v-btn',{staticClass:"elevation-0",attrs:{"block":"","rounded":"","color":_vm.$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-2'},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(" "+_vm._s(_vm.$i18n.t("Cancel"))+" ")])],1),_c('v-col',{staticClass:"px-3",attrs:{"cols":_vm.changeButtonSize}},[_c('v-btn',{staticClass:"elevation-0",attrs:{"block":"","rounded":"","color":"primary","loading":_vm.$props.loading,"disabled":_vm.$props.disabled},on:{"click":function($event){return _vm.$emit('continue')}}},[_vm._v(" "+_vm._s(_vm.$i18n.t(_vm.$props.saveButtonText))+" ")])],1)],1)],1):_vm._e()],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }