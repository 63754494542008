<template>
  <div :style="$props.field?.autoWidth ? '' : 'width: 100%'">
    <!-- Switch -->
    <v-switch
      v-if="$props.field.tag === 'switch'"
      :disabled="$props.field.disabled ? $props.field.disabled() : null"
      v-model="$attrs.value"
      dense
      hide-details="auto"
      :prepend-icon="$props.field.icon ? $props.field.icon() : null"
      class="mt-0"
      :true-value="
        typeof $props.field.trueValue !== 'undefined'
          ? $props.field.trueValue
          : true
      "
      :false-value="
        typeof $props.field.falseValue !== 'undefined'
          ? $props.field.falseValue
          : false
      "
      @change="$emit('input', $attrs.value)"
    >
      <template #label>
        <span>
          {{
            $attrs.value
              ? $i18n.t(
                  $props.field.textEnabled
                    ? $props.field.textEnabled
                    : "Enabled"
                )
              : $i18n.t(
                  $props.field.textDisabled
                    ? $props.field.textDisabled
                    : "Disabled"
                )
          }}
        </span>
      </template>
    </v-switch>
    <p
      v-if="$props.field.tag === 'switch' && $props.field.info"
      class="body-2 mb-0 pa-1 pl-8"
      :style="
        !$vuetify.theme.dark
          ? 'color: #00000060'
          : 'color: rgba(255, 255, 255, 0.5)'
      "
    >
      {{ $i18n.t($props.field.info) }}
    </p>

    <!-- Custom configuration switch fields -->

    <div v-if="$props.field.tag === 'configurationSwitch'" class="d-flex">
      <v-switch
        v-if="$props.field.switch"
        :disabled="$props.field.disabled ? $props.field.disabled() : null"
        v-model="$attrs.value"
        dense
        hide-details="auto"
        :prepend-icon="$props.field.icon ? $props.field.icon() : null"
        class="mt-0"
        :true-value="
          typeof $props.field.trueValue !== 'undefined'
            ? $props.field.trueValue
            : true
        "
        :false-value="
          typeof $props.field.falseValue !== 'undefined'
            ? $props.field.falseValue
            : false
        "
        @change="$emit('input', $attrs.value)"
      >
      </v-switch>
      <p
        v-if="!$props.field.subtitle"
        class="mb-0 pa-0"
        style="font-size: 16px; color: var(--text)"
      >
        {{ this.$i18n.t($props.field.text) }}
      </p>
      <p
        v-if="$props.field.subtitle"
        class="mb-0 pa-0"
        style="font-size: 20px; color: var(--text)"
      >
        {{ this.$i18n.t($props.field.text) }}
      </p>
    </div>
    <div
      v-if="$props.field.tag === 'configurationSwitch'"
      class="body-2 mb-0 pa-1"
      :class="$props.field.switch ? 'pl-12' : 'pl-0'"
    >
      <p
        :style="
          !$vuetify.theme.dark
            ? 'color: #00000060'
            : 'color: rgba(255, 255, 255, 0.5)'
        "
        v-if="$props.field.info"
      >
        {{ $i18n.t($props.field.info) }}
      </p>
      <v-row no-gutters class="mt-0" v-if="!$props.field.type">
        <v-col cols="2" v-for="(content, i) in $props.field.content" :key="i">
          <AutomaticField :field="content" />
        </v-col>
      </v-row>
      <v-row v-if="$props.field.type">
        <v-col
          :cols="
            $props.field.type() == 'text' || $props.field.type() == 'password'
              ? 5
              : 2
          "
        >
          <v-text-field
            v-if="$props.field.type() === 'number'"
            :class="$props.field.variant ? 'variant' : null"
            :light="light === undefined ? !$vuetify.theme.dark : light"
            :disabled="$props.field.disabled ? $props.field.disabled() : null"
            v-model.number="$attrs.value"
            :counter="$props.field.counter"
            :min="$props.field.min ? $props.field.min : '0'"
            :max="$props.field.max ? $props.field.max : null"
            :rules="$props.field.rules ? $props.field.rules() : []"
            :append-icon="
              $props.field.appendIcon ? $props.field.appendIcon() : null
            "
            :dense="$props.field.dense ? $props.field.dense : true"
            outlined
            hide-details="auto"
            @click:append="
              $props.field.appendClick ? $props.field.appendClick() : null
            "
            @input="changeNumberValue"
            @keyup.enter="
              $props.field.validate ? $props.field.validate() : null
            "
            @change="$props.field.change ? $props.field.change() : null"
            @keydown="notDecimal"
            @focusout="$props.field.focusout ? $props.field.focusout() : null"
            class="configuration-input"
          ></v-text-field>
          <v-text-field
            v-if="$props.field.type() !== 'number'"
            :light="light === undefined ? !$vuetify.theme.dark : light"
            :class="$props.field.variant ? 'variant' : null"
            :disabled="$props.field.disabled ? $props.field.disabled() : null"
            :readonly="$props.field.readonly ? $props.field.readonly() : null"
            v-model="$attrs.value"
            :type="$props.field.type()"
            :label="$i18n.t($props.field.text)"
            :counter="$props.field.counter"
            :rules="$props.field.rules ? $props.field.rules() : []"
            :append-icon="
              $props.field.appendIcon ? $props.field.appendIcon() : null
            "
            :dense="$props.field.dense ? $props.field.dense() : true"
            :hide-details="
              $props.field.hideDetails ? $props.field.hideDetails() : 'auto'
            "
            outlined
            @click:append="
              $props.field.appendClick
                ? $props.field.appendClick($props.field)
                : null
            "
            @keyup.enter="
              $props.field.validate ? $props.field.validate() : null
            "
            @input="$emit('input', $attrs.value)"
            @change="$props.field.change ? $props.field.change() : null"
            @click="$props.field.click ? $props.field.click() : null"
            @update:error="$emit('update:error', $event)"
            autocomplete="new-password"
            class="configuration-input"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <!-- Textfield -->
    <v-text-field
      v-if="
        $props.field.tag === 'textfield' && $props.field.type() !== 'number'
      "
      :light="light === undefined ? !$vuetify.theme.dark : light"
      :class="$props.field.variant ? 'variant' : null"
      :disabled="$props.field.disabled ? $props.field.disabled() : null"
      :readonly="$props.field.readonly ? $props.field.readonly() : null"
      v-model="$attrs.value"
      :type="$props.field.type()"
      :label="$i18n.t($props.field.text)"
      :counter="$props.field.counter"
      :rules="$props.field.rules ? $props.field.rules() : []"
      :append-icon="$props.field.appendIcon ? $props.field.appendIcon() : null"
      :dense="$props.field.dense ? $props.field.dense() : true"
      :hide-details="
        $props.field.hideDetails ? $props.field.hideDetails() : 'auto'
      "
      outlined
      @click:append="
        $props.field.appendClick ? $props.field.appendClick($props.field) : null
      "
      @keyup.enter="$props.field.validate ? $props.field.validate() : null"
      @input="$emit('input', $attrs.value)"
      @change="$props.field.change ? $props.field.change() : null"
      @click="$props.field.click ? $props.field.click() : null"
      @update:error="$emit('update:error', $event)"
      autocomplete="new-password"
    >
      <template #prepend-inner v-if="$props.field.innerIcon">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              icon
              small
              v-on="on"
              :disabled="$props.field.disabled ? $props.field.disabled() : null"
              @click.stop="$props.field.innerClick()"
            >
              <v-icon
                :color="
                  $props.field.innerIconColor
                    ? $props.field.innerIconColor()
                    : 'info'
                "
                small
              >
                {{ $props.field.innerIcon() }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $props.field.innerPlaceholder }}</span>
        </v-tooltip>
      </template>

      <template #append-outer v-if="$props.field.info">
        <v-tooltip bottom max-width="200px" color="rgba(0, 0, 0, 0.8)">
          <template #activator="{ on }">
            <v-icon color="info" v-on="on"> mdi-information </v-icon>
          </template>
          <span>{{ $i18n.t($props.field.info) }}</span>
        </v-tooltip>
      </template>
    </v-text-field>

    <!-- Textfield Number-->
    <v-text-field
      v-if="
        $props.field.tag === 'textfield' && $props.field.type() === 'number'
      "
      :class="$props.field.variant ? 'variant' : null"
      :light="light === undefined ? !$vuetify.theme.dark : light"
      :disabled="$props.field.disabled ? $props.field.disabled() : null"
      v-model.number="$attrs.value"
      :label="$i18n.t($props.field.text)"
      :counter="$props.field.counter"
      :min="$props.field.min ? $props.field.min : '0'"
      :max="$props.field.max ? $props.field.max : null"
      :rules="$props.field.rules ? $props.field.rules() : []"
      :append-icon="$props.field.appendIcon ? $props.field.appendIcon() : null"
      :dense="$props.field.dense ? $props.field.dense : true"
      outlined
      autocomplete="new-password"
      hide-details="auto"
      @click:append="
        $props.field.appendClick ? $props.field.appendClick() : null
      "
      @input="changeNumberValue"
      @keyup.enter="$props.field.validate ? $props.field.validate() : null"
      :prefix="$props.field.prefix ? $props.field.prefix : ''"
      @change="$props.field.change ? $props.field.change() : null"
      @keydown="notDecimal"
      @focusout="$props.field.focusout ? $props.field.focusout() : null"
      @blur="$props.field.blur ? $props.field.blur() : null"
    >
      <template #prepend-inner v-if="$props.field.innerIcon">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              :disabled="$props.field.disabled ? $props.field.disabled() : null"
              icon
              small
              v-on="on"
              @click.stop="$props.field.innerClick()"
            >
              <v-icon
                :color="
                  $props.field.innerIconColor
                    ? $props.field.innerIconColor()
                    : 'info'
                "
                small
              >
                {{ $props.field.innerIcon() }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $props.field.innerPlaceholder }}</span>
        </v-tooltip>
      </template>

      <template #append-outer v-if="$props.field.info">
        <v-tooltip bottom max-width="200px" color="rgba(0, 0, 0, 0.8)">
          <template #activator="{ on }">
            <v-icon color="info" v-on="on"> mdi-information </v-icon>
          </template>

          <span>{{ $i18n.t($props.field.info) }}</span>
        </v-tooltip>
      </template>
    </v-text-field>

    <!-- Search -->
    <v-text-field
      v-if="$props.field.tag === 'search'"
      v-model="$attrs.value"
      :label="$i18n.t('Search')"
      prepend-inner-icon="mdi-magnify"
      dense
      outlined
      clearable
      rounded
      hide-details="auto"
      autocomplete="new-password"
      @input="$emit('input', $attrs.value)"
      class="search"
      :class="$props.field.variant ? 'variant' : null"
      :style="$props.field.style"
    >
      <template #append v-if="$slots.append">
        <slot name="append"></slot>
      </template>
    </v-text-field>
    <!-- Autocomplete -->
    <v-autocomplete
      v-if="$props.field.tag === 'autocomplete'"
      v-model="$attrs.value"
      :light="light === undefined ? !$vuetify.theme.dark : light"
      :items="$props.field.items()"
      :type="$props.field.type ? $props.field.type : 'text'"
      :disabled="$props.field.disabled ? $props.field.disabled() : null"
      :filter="$props.field.customFilter"
      :label="
        $i18n.t(
          typeof $props.field.text === 'function'
            ? $props.field.text()
            : $props.field.text
        )
      "
      :multiple="$props.field.multiple ? $props.field.multiple() : null"
      :rules="$props.field.rules ? $props.field.rules() : []"
      :item-value="$props.field.itemValue"
      :item-text="$props.field.itemText"
      :clearable="$props.field.clearable ? $props.field.clearable : null"
      :return-object="$props.field.returnObject"
      hide-details="auto"
      outlined
      autocomplete="new-password"
      dense
      :chips="$props.field.chips"
      :small-chips="$props.field.chips"
      @change="
        $emit('input', $attrs.value);
        if ($props.field.change) $props.field.change();
      "
    >
      <template #append-outer v-if="$props.field.info">
        <v-tooltip bottom max-width="200px" color="rgba(0, 0, 0, 0.8)">
          <template #activator="{ on }">
            <v-icon color="info" v-on="on"> mdi-information </v-icon>
          </template>
          <span>{{ $i18n.t($props.field.info) }}</span>
        </v-tooltip>
      </template>

      <template
        #selection="{ item, index }"
        v-if="
          ['provider', 'countries', 'host'].includes($props.field.value) ||
          field.image ||
          $props.field.showLess ||
          $props.field.draggable
        "
      >
        <template v-if="$props.field.draggable">
          <draggable
            :id="index"
            :list="$attrs.value"
            v-bind="{
              animation: 200,
              group: 'group',
              disabled: false,
              ghostClass: 'ghost',
              sort: true,
            }"
            :move="move"
            @change="change"
          >
            <v-chip
              draggable
              v-model="$attrs.value[index]"
              :key="item + index"
              @mousedown.stop
              @click.stop
            >
              {{ item.text }}
            </v-chip>
          </draggable>
        </template>

        <template v-if="$props.field.value === 'provider' || item.image">
          <v-img
            class="mr-1"
            contain
            :max-height="
              $props.field.iconMaxHeight ? $props.field.iconMaxHeight() : '30'
            "
            :max-width="
              $props.field.iconMaxWidth ? $props.field.iconMaxWidth() : '30'
            "
            :src="item.image"
          >
          </v-img>
          {{ item.name }}
        </template>

        <template
          v-if="
            $props.field.value === 'countries' || $props.field.value === 'host'
          "
        >
          <v-chip
            v-if="index < 2"
            :input-value="item.iso2"
            :close="$props.field.multiple()"
            small
            @click:close="
              () => {
                if ($attrs.value.includes(item.iso2)) {
                  $attrs.value.splice($attrs.value.indexOf(item.iso2), 1);
                }
              }
            "
          >
            <div
              :class="
                item.iso2 ? 'vti__flag' + ' ' + item.iso2.toLowerCase() : null
              "
            ></div>
            {{ item.name }}
          </v-chip>

          <v-chip v-if="index === 2" small>
            {{ $attrs.value.length - 2 + " " + $i18n.t("more countries") }}
          </v-chip>
        </template>

        <template v-if="$props.field.showLess && !$props.field.draggable">
          <v-chip
            small
            v-if="$props.field.showCount && index < $props.field.maxItems"
          >
            <span>{{ index + 1 + " - " + item.text }}</span>
          </v-chip>

          <v-chip v-else-if="index < $props.field.maxItems" small>
            <span>{{
              $props.field.itemText ? item[$props.field.itemText] : item
            }}</span>
          </v-chip>
          <span
            v-if="index === $props.field.maxItems"
            class="grey--text text-caption px-2"
          >
            (+{{ $attrs.value.length - $props.field.maxItems }}
            {{ $i18n.t("More") }})
          </span>
        </template>
      </template>

      <template
        #item="{ item }"
        v-if="
          $props.field.icon || $props.field.subtitle || $props.field.countries
        "
      >
        <template v-if="$props.field.icon">
          <v-avatar
            size="24"
            class="mr-2"
            :color="item.color"
            :style="[{ 'background-color': item.color }]"
          >
            <v-icon size="16" color="white">{{ item.icon }}</v-icon>
          </v-avatar>
          {{ $i18n.t(item.text) }}
        </template>
        <template v-if="$props.field.subtitle">
          <v-list-item-content style="max-width: 200px">
            <v-list-item-title>
              <span class="font-weight-bold">
                {{ item.name }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="item.description"
              style="white-space: normal"
            >
              {{ $i18n.t(item.description) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template v-if="$props.field.countries">
          <div
            :class="
              item.iso2 ? 'vti__flag' + ' ' + item.iso2.toLowerCase() : null
            "
          ></div>
          {{ item.text }}
        </template>
      </template>

      <template
        #item="{ item }"
        v-if="
          ['provider', 'countries', 'host'].includes($props.field.value) ||
          field.image
        "
      >
        <template>
          <v-img
            v-if="$props.field.value === 'provider' || item.image"
            class="mr-1"
            contain
            max-height="30"
            max-width="30"
            :src="item.image"
          >
          </v-img>
        </template>

        <template
          v-if="
            $props.field.value === 'countries' || $props.field.value === 'host'
          "
        >
          <v-checkbox
            v-if="$props.field.multiple()"
            class="mr-2"
            :input-value="
              $attrs.value ? $attrs.value.includes(item.iso2) : null
            "
          >
          </v-checkbox>
          <div
            :class="
              item.iso2 ? 'vti__flag' + ' ' + item.iso2.toLowerCase() : null
            "
          ></div>
        </template>
        {{ item.name }}
      </template>

      <template #item="{ item }" v-if="$props.field.userSelect">
        <v-list-item-content style="max-width: 200px">
          <v-row no-gutters>
            <v-col cols="2">
              <v-list-item-icon>
                <v-avatar small size="32">
                  <img :src="avatarUrl(item.username, item.avatar)" />
                </v-avatar>
              </v-list-item-icon>
            </v-col>
            <v-col
              cols="10"
              class="d-flex flex-column justify-center align-baseline"
            >
              <v-list-item-title style="align-self: baseline">
                <span class="font-weight-bold">
                  {{ item.name }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle style="white-space: normal">
                @{{ item.username }}
              </v-list-item-subtitle>
            </v-col>
          </v-row>
        </v-list-item-content>
      </template>

      <template v-slot:prepend-item v-if="useSelectAll">
        <v-list-item ripple @mousedown.prevent @click="toggleSelectionAll()">
          <v-list-item-action>
            <v-icon
              :color="
                $attrs.value == null ||
                $attrs.value.length != $props.field.items().length
                  ? ''
                  : 'primary'
              "
            >
              {{
                $attrs.value == null ||
                $attrs.value.length != $props.field.items().length
                  ? "mdi-checkbox-blank-outline"
                  : "mdi-checkbox-marked"
              }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ $i18n.t("Select all") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-autocomplete>

    <!-- Select -->
    <v-select
      v-if="$props.field.tag === 'select'"
      :disabled="$props.field.disabled ? $props.field.disabled() : null"
      :light="light === undefined ? !$vuetify.theme.dark : light"
      :class="$props.field.variant ? 'variant' : null"
      append-icon="mdi-chevron-down"
      v-model="$attrs.value"
      :items="$props.field.items()"
      :label="
        $props.field.noLabel && $props.field.noLabel()
          ? ''
          : $i18n.t($props.field.text)
      "
      :placeholder="$props.field.placeholder ? $props.field.placeholder : ''"
      :rules="$props.field.rules ? $props.field.rules() : []"
      :multiple="
        $props.field.multiple && typeof $props.field.multiple == 'function'
          ? $props.field.multiple()
          : null
      "
      :item-value="$props.field.itemValue"
      :item-text="$props.field.itemText"
      :clearable="$props.field.clearable ? $props.field.clearable() : true"
      outlined
      dense
      hide-details="auto"
      :return-object="$props.field.returnObject"
      @change="
        $emit('input', $attrs.value);
        if ($props.field.change) {
          $props.field.change();
        }
      "
      @focusout="$props.field.focusout ? $props.field.focusout() : null"
    >
      <template #prepend v-if="$props.field.innerIcon">
        <div
          style="width: 100%; height: 100%"
          class="d-flex justify-center align-center noMargin"
        >
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click.stop="
                  $props.field.openDialog
                    ? $emit('openDialog')
                    : $props.field.method
                    ? $props.field.method()
                    : $props.field.innerClick()
                "
              >
                <v-icon
                  :color="
                    $props.field.innerIconColor
                      ? $props.field.innerIconColor()
                      : 'info'
                  "
                >
                  {{ $props.field.innerIcon() }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.t($props.field.innerPlaceholder) }}</span>
          </v-tooltip>
        </div>
      </template>

      <template
        #selection="{ item }"
        v-if="$props.field.icon || $props.field.countries || $props.field.iso2"
      >
        <template v-if="$props.field.icon">
          <v-avatar
            size="24"
            class="mr-2"
            :color="item.color"
            :style="[{ 'background-color': item.color }]"
          >
            <v-icon
              :size="item.iconSize ? item.iconSize : '16'"
              :color="item.iconColor ? item.iconColor : 'white'"
            >
              {{ item.icon }}
            </v-icon>
          </v-avatar>
          {{ $props.field.hideText ? "" : $i18n.t(item.text) }}
        </template>
        <template v-if="$props.field.countries || $props.field.iso2">
          <div
            :class="
              item.iso2 ? 'vti__flag' + ' ' + item.iso2.toLowerCase() : null
            "
            style="margin-right: 5px"
          ></div>
          {{ item.text }}
        </template>
      </template>

      <template
        #item="{ item }"
        v-if="
          $props.field.icon || $props.field.subtitle || $props.field.countries
        "
      >
        <template v-if="$props.field.icon">
          <v-avatar
            size="24"
            class="mr-2"
            :color="item.color"
            :style="[{ 'background-color': item.color }]"
          >
            <v-icon
              :size="item.iconSize ? item.iconSize : '16'"
              :color="item.iconColor ? item.iconColor : 'white'"
            >
              {{ item.icon }}
            </v-icon>
          </v-avatar>
          {{ $i18n.t(item.text) }}
        </template>
        <template v-if="$props.field.subtitle">
          <v-list-item-content style="max-width: 200px">
            <v-list-item-title>
              <span class="font-weight-bold">
                {{ item.name }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="item.description"
              style="white-space: normal"
            >
              {{ $i18n.t(item.description) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template v-if="$props.field.countries">
          <div
            :class="
              item.iso2 ? 'vti__flag' + ' ' + item.iso2.toLowerCase() : null
            "
          ></div>
          {{ item.text }}
        </template>
      </template>

      <template
        #append-outer
        v-if="$props.field.info || $props.field.play || $props.field.playError"
      >
        <v-tooltip
          v-if="$props.field.info"
          bottom
          max-width="200px"
          color="rgba(0, 0, 0, 0.8)"
        >
          <template #activator="{ on }">
            <v-icon color="info" v-on="on"> mdi-information </v-icon>
          </template>
          <span>{{ $i18n.t($props.field.info) }}</span>
        </v-tooltip>

        <v-tooltip v-if="$props.field.play" bottom max-width="200px">
          <template #activator="{ on }">
            <v-btn icon outlined color="info">
              <v-icon
                v-on="on"
                color="info"
                @click.stop="$props.field.appendClick($attrs.value)"
                :disabled="!$attrs.value"
              >
                {{ $props.field.isPlaying() ? "mdi-pause" : "mdi-play" }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $i18n.t($props.field.playInfo) }}</span>
        </v-tooltip>
        <v-tooltip v-if="$props.field.playError" bottom max-width="200px">
          <template #activator="{ on }">
            <v-btn icon outlined color="info">
              <v-icon
                v-on="on"
                color="info"
                @click.stop="$props.field.appendClick($attrs.value)"
                :disabled="!$attrs.value"
              >
                {{ $props.field.isPlaying() ? "mdi-pause" : "mdi-play" }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $props.field.playInfo }}</span>
        </v-tooltip>
      </template>
    </v-select>

    <!-- Checkbox -->
    <v-checkbox
      v-if="$props.field.tag === 'checkbox'"
      :disabled="$props.field.disabled ? $props.field.disabled() : null"
      v-model="$attrs.value"
      :label="
        $props.field.noTranslate
          ? $props.field.text
          : $i18n.t($props.field.text)
      "
      :rules="$props.field.rules ? $props.field.rules() : []"
      dense
      hide-details="auto"
      :light="light === undefined ? !$vuetify.theme.dark : light"
      class="mt-0"
      @click="checkBoxClick"
    >
      <template v-slot:label>
        <p class="ma-0" @click="checkboxLabelClick" style="width: 100%">
          <span v-if="$props.field.icon">
            <v-icon
              :color="$props.field.color ? $props.field.color : null"
              class="mr-2"
              size="18"
            >
              {{ $props.field.icon }}
            </v-icon>
          </span>
          <span class="font-weight-medium">{{
            $props.field.noTranslate
              ? $props.field.text
              : $i18n.t($props.field.text)
          }}</span>
        </p>
      </template>
    </v-checkbox>
    <p
      v-if="$props.field.tag === 'checkbox' && $props.field.info"
      class="body-2 mb-0 pa-1 pl-8"
      :style="
        !$vuetify.theme.dark
          ? 'color: #00000060'
          : 'color: rgba(255, 255, 255, 0.5)'
      "
      @click="notOpenContent"
    >
      {{
        $props.field.noTranslate
          ? $props.field.info
          : $i18n.t($props.field.info)
      }}
    </p>

    <!-- Combobox -->
    <v-combobox
      ref="combobox"
      v-if="$props.field.tag === 'combobox'"
      v-model="$attrs.value"
      :items="$props.field.items()"
      :disabled="$props.field.disabled ? $props.field.disabled() : null"
      :label="$i18n.t($props.field.text)"
      :multiple="$props.field.multiple ? $props.field.multiple() : null"
      :rules="$props.field.rules ? $props.field.rules() : []"
      :item-value="item => item[$props.field.itemValue]"
      :item-text="$props.field.itemText"
      append-icon="mdi-chevron-down"
      hide-details="auto"
      outlined
      autocomplete="new-password"
      dense
      :return-object="$props.field.returnObject"
      :chips="$props.field.chips ? $props.field.chips : null"
      :small-chips="$props.field.smallChips ? $props.field.smallChips : null"
      :deletable-chips="
        $props.field.deletableChips ? $props.field.deletableChips : null
      "
      @change="$emit('input', $attrs.value)"
      :class="{
        [field.class]: field.class,
        variant: field.variant,
      }"
    >
      <template #append-outer v-if="$props.field.info">
        <v-tooltip bottom max-width="200px" color="rgba(0, 0, 0, 0.8)">
          <template #activator="{ on }">
            <v-icon color="info" v-on="on"> mdi-information </v-icon>
          </template>
          <span>{{ $i18n.t($props.field.info) }}</span>
        </v-tooltip>
      </template>

      <template #selection="{ item }" v-if="$props.field.icon">
        <template>
          <v-avatar
            size="24"
            class="mr-2"
            :color="item.color"
            :style="[{ 'background-color': item.color }]"
          >
            <v-icon size="16" color="white">{{ item.icon }}</v-icon>
          </v-avatar>
          {{ $props.field.hideText ? "" : $i18n.t(item.text) }}
        </template>
      </template>

      <template #selection="{ item }" v-if="$props.field.updateItems">
        <template>
          <v-chip
            class="editable-chip"
            small
            @dblclick="editChip(item)"
            v-if="!editingChip"
            close
            close-icon="mdi-close"
            @click:close="$emit('remove', item)"
            :style="$props.field.chipStyle ? $props.field.chipStyle(item) : {}"
          >
            <span>{{ item }}</span>
          </v-chip>

          <v-text-field
            v-else-if="editingChip && editingChipSelected === item"
            v-click-outside="clickOutsideEditing"
            :ref="'editingChip' + item"
            v-model="editingChipValue"
            dense
            hide-details="auto"
            outlined
            @keyup.enter="validateNewChip(editingChipValue, $props.field.value)"
            @click.stop="positionCursor($event, item)"
          />
        </template>
      </template>

      <template
        #item="{ item }"
        v-if="
          $props.field.icon || $props.field.subtitle || $props.field.countries
        "
      >
        <template v-if="$props.field.icon">
          <v-avatar
            size="24"
            class="mr-2"
            :color="item.color"
            :style="[{ 'background-color': item.color }]"
          >
            <v-icon size="16" color="white">{{ item.icon }}</v-icon>
          </v-avatar>
          {{ $i18n.t(item.text) }}
        </template>
        <template v-if="$props.field.subtitle">
          <v-list-item-content style="max-width: 200px">
            <v-list-item-title>
              <span class="font-weight-bold">
                {{ item.name }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="item.description"
              style="white-space: normal"
            >
              {{ $i18n.t(item.description) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template v-if="$props.field.countries">
          <div
            :class="
              item.iso2 ? 'vti__flag' + ' ' + item.iso2.toLowerCase() : null
            "
          ></div>
          {{ item.text }}
        </template>
      </template>

      <template #prepend-inner v-if="$props.field.innerIcon">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn icon small v-on="on" @click.stop="$props.field.innerClick()">
              <v-icon
                :color="
                  $props.field.innerIconColor
                    ? $props.field.innerIconColor()
                    : 'info'
                "
                small
              >
                {{ $props.field.innerIcon() }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $props.field.innerPlaceholder }}</span>
        </v-tooltip>
      </template>
    </v-combobox>

    <!-- Text Area -->
    <v-textarea
      v-if="$props.field.tag === 'textarea'"
      outlined
      :light="light === undefined ? !$vuetify.theme.dark : light"
      :label="$i18n.t($props.field.text)"
      hide-details="auto"
      :disabled="$props.field.disabled ? $props.field.disabled() : null"
      v-model="$attrs.value"
      :class="$props.field.variant ? 'variant' : null"
      @input="$emit('input', $attrs.value)"
      @keydown="$emit('keydown', $event)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      @click="$emit('click', $event)"
      :no-resize="$props.field.noResize"
      :rules="$props.field.rules ? $props.field.rules() : null"
      :height="$props.field.height ? $props.field.height : null"
    >
      <template #append-outer v-if="$props.field.info">
        <v-tooltip bottom color="rgba(0, 0, 0, 0.8)">
          <template #activator="{ on }">
            <v-icon color="info" v-on="on"> mdi-information </v-icon>
          </template>
          <span>{{ $i18n.t($props.field.info) }}</span>
        </v-tooltip>
      </template>

      <template #append v-if="$props.field.innerClick">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              class="templatesIcon"
              icon
              small
              v-on="on"
              @click.prevent.stop="$props.field.innerClick()"
            >
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : '#505253'"
                size="24"
              >
                mdi-file-document-outline
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $i18n.t($props.field.innerPlaceholder) }}</span>
        </v-tooltip>
      </template>
    </v-textarea>

    <!-- Vue2 Editor -->
    <VueEditor
      v-if="$props.field.tag === 'vueEditor'"
      :field="$props.field"
      v-model="$attrs.value"
      ref="vueEditor"
      :class="$props.field.variant ? 'variant' : null"
      :placeholder="$i18n.t($props.field.placeholder)"
      :height="$props.field.height"
      @input="manageEditorInput"
      :attachmentsMessage="
        $props.field.attachments ? $props.field.attachments() : []
      "
      @addAttachment="$emit('addAttachment', $event)"
      @removeAttachement="$emit('removeAttachement', $event)"
    >
      <template #header>
        <slot name="header"></slot>
      </template>
      <template #left>
        <slot name="left"></slot>
      </template>
      <template #right>
        <slot name="right"></slot>
      </template>
    </VueEditor>

    <!-- Radio -->
    <v-radio-group
      v-if="$props.field.tag === 'radio'"
      :disabled="$props.field.disabled ? $props.field.disabled() : null"
      v-model="$attrs.value"
      row
      dense
      hide-details="auto"
      class="mt-0 pt-0 align-center"
      :rules="$props.field.rules ? $props.field.rules() : null"
    >
      <v-radio
        v-for="option in $props.field.options"
        :key="option.value"
        :label="$i18n.t(option.text)"
        :value="option.value"
        class="pa-3"
        @click="$emit('input', $attrs.value)"
      />
      <template #append v-if="$props.field.info">
        <v-tooltip bottom max-width="200px" color="rgba(0, 0, 0, 0.8)">
          <template #activator="{ on }">
            <v-icon color="info" class="pa-3" v-on="on">
              mdi-information
            </v-icon>
          </template>
          <span>{{ $i18n.t($props.field.info) }}</span>
        </v-tooltip>
      </template>
    </v-radio-group>

    <!-- ColorPicker -->
    <v-menu
      v-if="$props.field.tag === 'colorPicker'"
      :ref="$props.field.ref"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
      hide-details="auto"
      @input="$emit('input', $attrs.value)"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :disabled="$props.field.disabled ? $props.field.disabled() : null"
          v-model="$attrs.value"
          :label="$i18n.t($props.field.text, $props.field.textParams || {})"
          readonly
          dense
          outlined
          hide-details="auto"
          v-bind="attrs"
          v-on="on"
          autocomplete="new-password"
        >
          <template #append-outer v-if="$props.field.info">
            <v-tooltip bottom max-width="200px" color="rgba(0, 0, 0, 0.8)">
              <template #activator="{ on }">
                <v-icon color="info" v-on="on"> mdi-information </v-icon>
              </template>
              <span>{{ $i18n.t($props.field.info) }}</span>
            </v-tooltip>
          </template>
          <template #prepend-inner>
            <v-icon style="z-index: 2" :color="$attrs.value">
              mdi-brush-outline
            </v-icon>
          </template>
        </v-text-field>
      </template>
      <v-color-picker
        dot-size="30"
        mode="hexa"
        v-model="$attrs.value"
        @input="$emit('input', $attrs.value)"
      ></v-color-picker>
    </v-menu>

    <!-- N2P ColorPicker -->
    <v-col cols="12" v-if="$props.field.tag == 'n2pColorPicker'">
      <v-row no-gutters>
        <v-col
          v-for="(color, index) in colorList"
          :key="'color-' + index"
          cols="4"
          md="2"
          class="pa-3"
        >
          <v-hover #default="{ hover }">
            <v-card
              class="rounded-xl"
              flat
              outlined
              style="height: 60px"
              :style="[
                {
                  'background-color': !$vuetify.theme.dark
                    ? color.light
                    : color.dark,
                },
              ]"
              @click="
                $attrs.value = color.light;
                $emit('input', $attrs.value);
              "
            >
              <v-fade-transition v-if="hover || $attrs.value === color.light">
                <v-overlay
                  style="position: absolute; z-index: 1; height: 100%"
                  color="black"
                  opacity="0.2"
                  class="color-overlay"
                >
                  <v-icon large>mdi-check</v-icon>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-col>
    <!-- Icon picker -->
    <template>
      <v-col cols="12" v-if="$props.field.tag == 'iconPicker'">
        <span
          v-if="$props.field.text"
          class="font-weight-bold"
          style="font-size: 17px"
          :style="
            $vuetify.theme.dark ? 'color: white' : 'color: #505253 !important'
          "
          >{{ $i18n.t($props.field.text) }}</span
        >
        <v-row no-gutters>
          <v-col
            v-for="(icon, index) in $props.field.icons"
            :key="index"
            cols="4"
            md="2"
            class="pa-3"
          >
            <div
              style="cursor: pointer"
              :class="$vuetify.theme.dark ? 'shadow-md' : 'shadow-sm'"
              class="rounded-xl pa-3 text-center"
              outlined
              flat
              :disabled="$props.field.disabled"
              @click="
                $attrs.value = icon;
                $emit('input', $attrs.value);
              "
            >
              <v-icon size="40" :color="$attrs.value === icon ? 'primary' : ''">
                {{ icon }}
              </v-icon>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <!-- Iframe -->
    <v-col
      v-if="$props.field.tag === 'iframe'"
      cols="12"
      sm="12"
      md="12"
      class="pt-3 px-3"
    >
      <v-row
        no-gutters
        class="align-center rounded-lg"
        style="border: 2px solid var(--v-primary-base)"
      >
        <iframe
          class="pa-3"
          :src="$props.field.url"
          width="100%"
          height="500px"
          style="border: none"
        >
        </iframe>
      </v-row>
    </v-col>

    <!-- ConnectorImg -->
    <v-img
      :src="$props.field.img()"
      contain
      :height="$props.field.height ? $props.field.height() : '100'"
      v-if="$props.field.tag === 'connectorImg'"
    >
      <template #placeholder>
        <Loader :size="50" />
      </template>
    </v-img>

    <!-- FullSelect -->
    <FullSelect
      v-if="$props.field.tag === 'fullselect'"
      v-model="$attrs.value"
      :data="$props.field"
      @input="$emit('input', $attrs.value)"
      :maxWidth="$props.field.maxWidth"
    />

    <!-- Subtitle -->
    <v-row
      v-if="$props.field.tag === 'subtitle'"
      no-gutters
      class="align-center pb-0"
      :class="$props.field.btnIcon ? 'pa-0' : 'pa-3'"
    >
      <v-tooltip bottom v-if="$props.field.btnIcon">
        <template #activator="{ on }">
          <v-btn
            icon
            v-on="on"
            @click.stop="$props.field.btnClick()"
            class="mr-2"
          >
            <v-icon color="info">
              {{ $props.field.btnIcon() }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $props.field.btnPlaceholder }}</span>
      </v-tooltip>

      <v-icon
        small
        v-if="$props.field.icon"
        class="mr-2"
        :color="$props.field.icon.color"
      >
        {{ $props.field.icon.icon }}
      </v-icon>

      <span class="text-h6 text--secondary font-weight-bold">
        {{ $i18n.t($props.field.text) }}
      </span>

      <!-- Info -->
      <v-col cols="12" v-if="$props.field.tooltipText">
        <p class="body-2 ma-0">
          {{ $i18n.t($props.field.info) }}
        </p>
      </v-col>

      <v-tooltip
        bottom
        left
        v-else-if="$props.field.info"
        max-width="200px"
        color="rgba(0, 0, 0, 0.8)"
      >
        <template #activator="{ on }">
          <v-icon color="info" v-on="on" class="ml-3"> mdi-information </v-icon>
        </template>

        <span>{{ $i18n.t($props.field.info) }}</span>
      </v-tooltip>

      <!-- Icon after subtitle -->
      <v-tooltip v-if="$props.field.afterIcon && !$props.field.info" top>
        <template #activator="{ on }">
          <v-btn
            v-on="on"
            icon
            small
            :color="
              $props.field?.btnColor == 'noColor' ? 'var(--text)' : 'primary'
            "
            @click="$props.field.afterIconClick()"
          >
            <v-icon>{{ $props.field.afterIcon() }}</v-icon>
          </v-btn>
        </template>
        <span>
          {{ $i18n.t($props.field.afterIconTooltip()) }}
        </span>
      </v-tooltip>
    </v-row>

    <!-- Button -->
    <v-btn
      v-if="$props.field.tag === 'button'"
      :color="$props.field.color ? $props.field.color() : ''"
      :rounded="$props.field.rounded"
      :outlined="
        $props.field.outlined != undefined ? $props.field.outlined : false
      "
      :light="light === undefined ? !$vuetify.theme.dark : light"
      elevation="0"
      @click="$props.field.onClick()"
      :loading="$props.field.loading ? $props.field.loading() : false"
      :class="$props.field.btnClass"
      :large="$props.field.large ? $props.field.large : false"
      :disabled="$props.field.disabled ? $props.field.disabled() : false"
      :small="$props.field.small"
      block
    >
      <v-icon class="mr-2" v-if="$props.field.icon">{{
        $props.field.icon
      }}</v-icon>
      {{ $i18n.t($props.field.text) }}
    </v-btn>

    <!-- DatePicker -->
    <v-menu
      v-if="$props.field.tag === 'datePicker'"
      v-model="$props.field.menu"
      :close-on-content-click="true"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
      :disabled="$props.field.disabledMenu()"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :disabled="$props.field.disabled()"
          v-model="$attrs.value"
          :label="$i18n.t($props.field.text)"
          prepend-inner-icon="mdi-calendar-outline"
          outlined
          dense
          readonly
          clearable
          @change="$emit('input', $attrs.value)"
          hide-details="auto"
          v-bind="attrs"
          v-on="on"
          autocomplete="new-password"
          :rules="$props.field.rules ? $props.field.rules() : null"
        >
          <template #append-outer v-if="$props.field.info">
            <v-tooltip bottom max-width="200px" color="rgba(0, 0, 0, 0.8)">
              <template #activator="{ on }">
                <v-icon color="info" v-on="on"> mdi-information </v-icon>
              </template>
              <span>{{ $i18n.t($props.field.info) }}</span>
            </v-tooltip>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="$attrs.value"
        color="primary"
        :max="$props.field.maxDataDate ? $props.field.maxDataDate() : null"
        :min="$props.field.minDataDate ? $props.field.minDataDate() : null"
        @input="$emit('input', $attrs.value)"
        :locale="useAppStore.preference.language"
      >
      </v-date-picker>
    </v-menu>

    <!-- TimePicker -->
    <v-menu
      v-if="$props.field.tag === 'timePicker'"
      v-model="$props.field.menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      :return-value.sync="$attrs.value"
      offset-y
      :max-width="$props.field.maxWidth"
      :min-width="$props.field.minWidth"
      :ref="$props.field.ref"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="$attrs.value"
          :label="$i18n.t($props.field.text)"
          prepend-inner-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="attrs"
          :disabled="$props.field.disabled()"
          v-on="on"
          hide-details="auto"
          :rules="$props.field.rules ? $props.field.rules() : []"
          autocomplete="new-password"
          outlined
          dense
        >
          <template #append-outer v-if="$props.field.info">
            <v-tooltip bottom max-width="200px" color="rgba(0, 0, 0, 0.8)">
              <template #activator="{ on }">
                <v-icon color="info" v-on="on"> mdi-information </v-icon>
              </template>

              <span>{{ $i18n.t($props.field.info) }}</span>
            </v-tooltip>
          </template>
        </v-text-field>
      </template>
      <v-time-picker
        v-model="$attrs.value"
        full-width
        :format="useAppStore.preference.language === 'en' ? 'ampm' : '24hr'"
        :min="$props.field.minTime ? $props.field.minTime() : null"
        :max="$props.field.maxTime ? $props.field.maxTime() : null"
        @click:minute="
          $refs[$props.field.ref].save($attrs.value);
          $emit('input', $attrs.value);
        "
      ></v-time-picker>
    </v-menu>

    <!--DateTimePicker-->
    <DateTimePicker
      v-if="$props.field.tag === 'dateTimePicker'"
      :label="$i18n.t($props.field.text)"
      :value="$attrs.value"
      :disabled="$props.field.disabled ? $props.field.disabled() : null"
      :rules="$props.field.rules ? $props.field.rules() : []"
      :info="$props.field.info ? $i18n.t($props.field.info) : null"
      :maxDate="$props.field.maxDate ? $props.field.maxDate() : null"
      :minDate="$props.field.minDate ? $props.field.minDate() : null"
      :locale="useAppStore.preference.language"
      :allowedDates="
        $props.field.allowedDates ? $props.field.allowedDates : null
      "
      :allowedHours="
        $props.field.allowedHours ? $props.field.allowedHours : null
      "
      :allowedMinutes="
        $props.field.allowedMinutes ? $props.field.allowedMinutes : null
      "
      :type="$props.field.type ? $props.field.type() : 'date'"
      :minTime="$props.field.minTime ? $props.field.minTime : null"
      :maxTime="$props.field.maxTime ? $props.field.maxTime : null"
      @input="
        value => {
          $emit('input', value);
        }
      "
    />

    <!-- Avatar -->
    <v-col
      v-if="$props.field.tag === 'avatar'"
      cols="12"
      class="pa-3 text-center"
    >
      <v-avatar small size="100" class="mr-2">
        <img :src="$props.field.avatarUrl()" />
      </v-avatar>
      <v-badge
        v-if="$props.field.badge()"
        class="customBadge"
        offset-x="37"
        offset-y="-3"
        color="success"
        bordered
        bottom
        overlap
      >
        <template #badge>
          <v-btn icon style="z-index: 1" @click="$props.field.onClick()">
            <v-icon color="white"> mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-badge>
    </v-col>

    <!-- Expansion panel -->
    <v-col v-if="$props.field.tag === 'expansion-panel'" cols="12">
      <v-expansion-panels
        class="rounded-xl"
        accordion
        flat
        :style="[
          {
            border: !$props.field.border
              ? $vuetify.theme.dark
                ? 'solid thin rgba(255, 255, 255, 0.12)'
                : 'solid thin rgba(0, 0, 0, 0.12)'
              : '0',
          },
        ]"
        @change="$emit('input', $attrs.value)"
        v-if="$attrs.value"
      >
        <v-expansion-panel
          v-for="(panel, index) in $props.field.items"
          :key="index"
          :style="[
            {
              border: !$props.field.border
                ? $vuetify.theme.dark
                  ? 'solid thin rgba(255, 255, 255, 0.12)'
                  : 'solid thin rgba(0, 0, 0, 0.12)'
                : '0',
            },
          ]"
        >
          <v-expansion-panel-header class="pa-3 px-5">
            <AutomaticField
              v-model="$attrs.value[index][panel.header.value]"
              :field="panel.header"
              @change="$emit('input', $attrs.value[index][panel.header.value])"
              @openContent="openContentExpansionPanel(index, panel)"
              @checkClick="checkClick($event, index)"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content :ref="'expansionContent' + index">
            <v-row no-gutters>
              <v-col
                cols="12"
                class="pa-3"
                v-for="(content, i) in panel.content"
                :key="i"
              >
                <AutomaticField
                  v-if="$attrs.value[index][content.value] != undefined"
                  v-model="$attrs.value[index][content.value]"
                  :field="content"
                  @change="$emit('input', $attrs.value[index][content.value])"
                />
                <AutomaticField
                  v-else
                  v-model="$attrs.value"
                  :field="content"
                  @change="$emit('input', $attrs.value)"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <!-- List Checkbox -->
    <v-list
      v-if="$props.field.tag === 'check-list'"
      flat
      dense
      :max-height="$props.field.maxHeight"
      class="overflow-y-auto pa-0 transparent"
    >
      <v-list-item-group
        v-model="$attrs.value"
        multiple
        @change="$emit('input', $attrs.value)"
      >
        <v-list-item
          v-for="(item, index) in $props.field.items()"
          :key="index"
          :value="item"
        >
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active" color="primary"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <!-- Regular List -->
    <v-list
      rounded
      class="fill-height pa-0"
      v-if="$props.field.tag === 'regular-list'"
      :key="$props.field.key()"
    >
      <v-list-item
        v-for="(item, index) in this.$attrs.value[this.$props.field.index][
          this.$props.field.propertie
        ]"
        :key="index"
        link
      >
        <v-list-item-content>
          <v-row no-gutters class="align-center">
            <v-col
              cols="1"
              v-if="
                $props.field.appendItem &&
                Array.isArray($props.field.appendItems()) &&
                $props.field.appendItems().find(i => {
                  return i.name == item.name;
                })
              "
            >
              <v-btn icon @click="$props.field.appendClick(item)">
                <v-icon>
                  {{
                    $props.field.appendItems().find(i => {
                      return i.name == item.name && i.language == item.language;
                    }).isPlaying
                      ? "mdi-pause"
                      : "mdi-play"
                  }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="11" :class="$props.field.appendItem ? 'pl-5' : null">
              <v-list-item-title>{{
                item.alterName ? item.alterName : item.name
              }}</v-list-item-title>
            </v-col>
          </v-row>
        </v-list-item-content>

        <v-list-item-action v-if="$props.field.actions">
          <ActionsMenu :data="item" :actions="$props.field.actions" />
        </v-list-item-action>
      </v-list-item>
      <p
        class="text-subtitle-1 text--secondary text-center"
        v-if="
          this.$attrs.value[this.$props.field.index][
            this.$props.field.propertie
          ].length == 0
        "
      >
        {{ $i18n.t(this.$props.field.noItemsText) }}
      </p>
    </v-list>

    <!-- Prism editor-->
    <div v-if="$props.field.tag === 'prism-editor'" style="position: relative">
      <prism-editor
        class="my-editor rounded"
        :style="{
          'max-height': $props.field.maxHeight
            ? $props.field.maxHeight
            : '300px',
        }"
        v-model="$attrs.value"
        :readonly="$props.field.disabled ? $props.field.disabled() : null"
        @input="$emit('input', $attrs.value)"
        :highlight="highlightCode"
        :line-numbers="$props.field.showLineNumbers"
      />
      <v-btn
        v-if="$props.field.showCopy"
        icon
        small
        style="position: absolute; bottom: 0; right: 0"
        class="mr-1 mb-1"
        @click="$props.field.copyCode()"
      >
        <v-icon small> mdi-content-copy </v-icon>
      </v-btn>
    </div>

    <!-- File-List -->
    <div v-if="$props.field.tag === 'list'">
      <div style="display: flex">
        <span>{{ $i18n.t($props.field.text) }}</span>
        <v-btn
          style="margin-left: auto"
          icon
          color="primary"
          @click="openImport"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <input
        type="file"
        :accept="accepted"
        :ref="$props.field.value + 'filePicker'"
        style="display: none"
        @change="handleImport($event.target.files)"
      />
      <div display v-for="attachment in files" :key="attachment.value">
        <span style="font-size: 11px">
          {{ attachment.text }}
        </span>
        <v-btn icon color="error" @click="removeFile(attachment)">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </div>
    </div>

    <!-- RadioText -->
    <div
      v-if="$props.field.tag === 'radioText'"
      class="d-flex flex-row flex-wrap align-center fill-height"
      style="row-gap: 8px"
    >
      <v-hover
        v-slot="{ hover }"
        v-for="(option, index) in $props.field
          .options()
          .filter(o => (o.show ? o.show() : true))"
        :key="index"
      >
        <div
          v-if="option.value?.key != 'customDate'"
          class="px-3 text-subtitle-1"
          :style="[
            {
              'font-weight': $attrs.value === option.value ? 'bold' : null,

              cursor: hover ? 'pointer' : null,

              color: hover ? 'var(--v-primary-base)' : '',
            },
          ]"
          :class="[
            index === 0 ? 'pr-3' : 'px-3',
            $attrs.value === option.value ? 'primary--text' : null,
          ]"
          @click="
            $attrs.value = option.value;
            $emit('input', $attrs.value);
          "
        >
          <v-icon
            v-if="option.icon"
            :color="
              hover && $attrs.value !== option.value
                ? `primary ${$vuetify.theme.dark ? 'lighten' : 'darken'}-3`
                : $attrs.value === option.value
                ? 'primary '
                : null
            "
          >
            {{ option.icon() }}
          </v-icon>
          {{ $i18n.t(option.text) }}
        </div>
        <v-menu
          v-else
          bottom
          offset-y
          :close-on-content-click="false"
          v-model="customDateMenu"
        >
          <template #activator="{ on, attrs }">
            <v-hover v-slot="{ hover }">
              <div
                v-on="on"
                v-bind="attrs"
                class="px-3 text-subtitle-1"
                :style="[
                  {
                    'font-weight':
                      $attrs.value?.key === option.value?.key ? 'bold' : null,
                    cursor: hover ? 'pointer' : null,
                    color: hover ? 'var(--v-primary-base)' : '',
                  },
                ]"
                :class="[
                  $attrs.value?.key === option.value?.key
                    ? 'primary--text'
                    : null,
                ]"
              >
                <v-icon
                  v-if="option.icon"
                  :color="
                    hover && $attrs.value !== option.value
                      ? 'primary lighten-3'
                      : $attrs.value === option.value
                      ? 'primary '
                      : 'white'
                  "
                >
                  {{ option.icon() }}
                </v-icon>
                {{ $i18n.t(option.text) }}
              </div>
            </v-hover>
          </template>
          <v-card style="width: 290px">
            <v-row no-gutters>
              <v-col cols="12">
                <v-date-picker
                  color="primary"
                  range
                  full-width
                  v-model="customDates"
                  :locale="useAppStore.preference.language"
                >
                </v-date-picker>
              </v-col>
              <v-col cols="6" class="pa-3 d-flex justify-center align-center">
                <v-btn outlined rounded block @click="customDateMenu = false">
                  {{ $i18n.t("Cancel") }}
                </v-btn>
              </v-col>
              <v-col cols="6" class="pa-3 d-flex justify-center align-center">
                <v-btn
                  color="primary"
                  rounded
                  block
                  elevation="0"
                  @click="
                    $attrs.value = {
                      ...option.value,
                      customDates: [...customDates],
                    };
                    customDateMenu = false;
                    $emit('input', $attrs.value);
                  "
                >
                  {{ $i18n.t("Confirm") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>
      </v-hover>
      <template v-if="$props.field.info">
        <v-tooltip bottom max-width="200px" color="rgba(0, 0, 0, 0.8)">
          <template #activator="{ on }">
            <v-icon color="info" class="pa-3" v-on="on">
              mdi-information
            </v-icon>
          </template>
          <span>{{ $i18n.t($props.field.info) }}</span>
        </v-tooltip>
      </template>
    </div>

    <!-- ButtonList -->
    <div
      v-if="$props.field.tag === 'buttonList'"
      class="d-flex"
      style="justify-content: space-between"
    >
      <v-btn
        icon
        :class="item.value + ' ' + item.activeStyle"
        height="auto"
        max-width="36px"
        max-height="36px"
        v-for="(item, index) in $props.field.items()"
        :key="index"
        @click="toggleChannel(item, $attrs.value)"
        :ripple="$props.field.ripple"
        :style="'order:' + index"
      >
        <v-avatar
          size="30"
          :style="{
            background: $attrs.value.find(c => c == item.value)
              ? item.background
              : $vuetify.theme.dark
              ? 'var(--v-background-base)'
              : '#d2d4d5',
          }"
        >
          <v-icon
            size="18"
            :color="
              $vuetify.theme.dark
                ? '#ffffff'
                : $attrs.value.find(c => c == item.value)
                ? '#ffffff'
                : '#505253'
            "
          >
            {{ item.icon }}
          </v-icon>
        </v-avatar>
        <slot
          v-if="item.activeStyle == 'active_channel'"
          name="menu"
          :item="item"
        />
      </v-btn>
    </div>
    <!--Import file-->
    <div v-if="$props.field.tag === 'importFile'">
      <importFile
        v-model="$attrs.value"
        :disabled="$props.field.disabled ? $props.field.disabled() : null"
        :multiple="$props.field.multiple ? $props.field.multiple() : null"
        :accept="$props.field.accept ? $props.field.accept() : null"
        :maxSize="$props.field.maxSize ? $props.field.maxSize() : null"
        :maxFiles="$props.field.maxFiles ? $props.field.maxFiles() : null"
        :hideList="$props.field.hideList ? $props.field.hideList() : null"
        @input="$emit('input', $attrs.value)"
        @input:file="$emit('input:file', $event)"
      />
    </div>

    <!-- AutoSuggest -->
    <AutoSuggest
      v-if="$props.field.tag === 'autoSuggest'"
      :label="$props.field.text"
      :suggestions="$props.field.suggestions ? $props.field.suggestions() : []"
      :suggestionText="$props.field.suggestionText"
      :suggestionValue="$props.field.suggestionValue"
      :disabled="$props.field.disabled ? $props.field.disabled() : null"
      :readonly="$props.field.readonly ? $props.field.readonly() : null"
      :splitter="$props.field.splitter"
      v-model="$attrs.value"
      :value="$attrs.value"
      @input="$emit('input', $event)"
    />

    <!-- Slider -->
    <div v-if="$props.field.tag === 'slider'">
      <span class="text-caption"
        >{{ $i18n.t(field.text) }}
        {{
          field.showValue && field.showValue()
            ? " - " + $attrs.value + (field.suffix || "")
            : ""
        }}
      </span>
      <v-slider
        v-model="$attrs.value"
        hide-details="auto"
        :disabled="$props.field.disabled ? $props.field.disabled() : false"
        :max="$props.field.max ? $props.field.max() : 100"
        :min="$props.field.min ? $props.field.min() : 0"
        :step="$props.field.step ? $props.field.step() : 1"
        :ticks="$props.field.ticks ? $props.field.ticks() : false"
        :tick-labels="$props.field.tickLabels ? $props.field.tickLabels() : []"
        :thumb-label="
          $props.field.thumbLabel ? $props.field.thumbLabel() : false
        "
        :thumb-size="$props.field.thumbSize ? $props.field.thumbSize() : 32"
        :vertical="$props.field.vertical || false"
        @input="$emit('input', $attrs.value)"
        @end="$emit('end', $attrs.value)"
      >
        <template v-slot:thumb-label="{ value }">
          {{ value + (field.suffix || "") }}
        </template>
        <template #append-outer v-if="$props.field.info">
          <v-tooltip bottom max-width="200px" color="rgba(0, 0, 0, 0.8)">
            <template #activator="{ on }">
              <v-icon color="info" v-on="on"> mdi-information </v-icon>
            </template>
            <span>{{ $i18n.t($props.field.info) }}</span>
          </v-tooltip>
        </template>
      </v-slider>
    </div>

    <!-- Big text radio buttons -->
    <v-radio-group
      v-if="$props.field.tag === 'bigRadioButtons'"
      :disabled="$props.field.disabled ? $props.field.disabled() : null"
      v-model="$attrs.value"
      row
      dense
      hide-details="auto"
      class="mt-0 pa-0 align-center"
      :rules="$props.field.rules ? $props.field.rules() : []"
    >
      <v-row no-gutters>
        <v-col
          :cols="$props.field.cols"
          v-for="option in $props.field.options"
          :key="option.value"
        >
          <v-radio
            :value="option.value"
            class="pa-3 bigRadio"
            :style="{
              'background-color': $vuetify.theme.dark
                ? 'var(--v-background-darken2)'
                : 'var(--v-background-base)',
              'border-radius': '8px',
              height: '100% !important',
            }"
            @click="$emit('input', $attrs.value)"
          >
            <template v-slot:label>
              <h4 class="mb-3">{{ $i18n.t(option.text) }}</h4>
              <span
                :style="{
                  color: $vuetify.theme.dark ? 'var(--text)' : '#818384',
                }"
                >{{ $i18n.t(option.info) }}</span
              >
            </template>
          </v-radio>
        </v-col>
      </v-row>
    </v-radio-group>
  </div>
</template>
<script>
import FullSelect from "@/components/base/FullSelect";

import VueEditor from "@/components/base/VueEditor/Editor";
import DateTimePicker from "@/components/base/DateTimePicker";
import AutoSuggest from "@/components/base/AutoSuggest";

import { useAppStore, inbox, avatarUrl } from "@/utils";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-sql";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-markup";
import "prismjs/components/prism-xml-doc";
import "prismjs/themes/prism-tomorrow.css";
import draggable from "vuedraggable";
import ImportFile from "@/components/base/ImportFile.vue";
import "@/assets/icons/css/countries.css";

export default {
  name: "AutomaticField",

  components: {
    FullSelect,
    VueEditor,
    DateTimePicker,
    AutoSuggest,
    PrismEditor,
    draggable,
    ImportFile,
  },

  props: {
    field: { type: Object },
    valid: { type: Boolean },
    light: { type: Boolean },
  },

  data() {
    return {
      useAppStore: useAppStore(),
      colorList: [
        { light: "#0095FF", dark: "#0084e1" },
        { light: "#242526", dark: "#003866" },
        { light: "#1685b5", dark: "#1086b6" },
        { light: "#90ce4c", dark: "#83ad52" },
        { light: "#ff9800", dark: "#d78100" },
        { light: "#f44349", dark: "#ba2a30" },
      ],
      highlightedCode: "",
      files: [],
      accepted:
        ".mp3, .wav, .ogg, .acc, .jpg, .png, .jpeg, .gif, .tiff, .mp4, .mov, .wmv, .avi, .txt, .pdf, .rar, .zip, ",
      customDates: [],
      customDateMenu: false,
      dragged: {
        from: -1,
        to: -1,
        newIndex: -1,
      },
      avatarUrl,
      editingChip: false,
      editingChipSelected: null,
      editingChipValue: "",
    };
  },

  created() {
    if (
      this.$props.field.tag == "expansion-panel" &&
      this.$attrs.value &&
      this.$attrs.value.length
    ) {
      this.$attrs.value.map(v => {
        if (v.active || v.syslog) {
          v.active = v.active == "0" ? 0 : 1;
          v.syslog = v.syslog == "0" ? 0 : 1;
        }
      });
    }

    if (
      this.$props.field.tag == "textfield" &&
      this.$props.field.type() == "number"
    ) {
      this.$attrs.value = this.$attrs.value
        ? this.$attrs.value
        : this.$props.field.default !== undefined
        ? this.$props.field.default
        : 0;
    }
    if (this.$props.field.tag == "list") {
      this.files = [...this.$attrs.value];
    }
    if (this.$props.field.tag == "radioText" && this.$attrs.value.customDates) {
      this.customDates = [...this.$attrs.value.customDates];
    }
  },

  methods: {
    notDecimal(e) {
      if (
        isNaN(e.key) &&
        e.key != "Backspace" &&
        e.key != "Delete" &&
        e.key != "ArrowLeft" &&
        e.key != "ArrowRight" &&
        e.key != "ArrowUp" &&
        e.key != "ArrowDown" &&
        e.key != "Control" &&
        e.key != "v"
      ) {
        e.preventDefault();
      }
    },
    inputFieldNumber(e) {
      if (e.includes(".") || e.includes(",")) {
        this.$attrs.value = Math.ceil(e);
      }
      if (e.includes("-")) {
        this.$attrs.value = Math.abs(e);
      }
      this.$emit("input", this.$attrs.value);
    },
    manageEditorInput(text, isEmpty, vueEditor, fileList) {
      this.$emit("manage", text, isEmpty, vueEditor, fileList);
      this.$emit("input", this.$attrs.value);
    },

    checkboxLabelClick() {
      if (this.$props.field.disabledLabelCheck) {
        this.$emit("openContent");
      }
    },
    notOpenContent(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    openContentExpansionPanel(index, panel, aux) {
      if (!aux) {
        this.$attrs.value[index][panel.header.value] =
          !this.$attrs.value[index][panel.header.value];
      }
    },
    checkBoxClick(e) {
      if (!this.$attrs.value) {
        this.$emit("checkClick", e);
      }
      2;
      this.$emit("input", this.$attrs.value);
    },
    checkClick(e, index) {
      if (
        (this.$refs["expansionContent" + index][0].$el.style?.display !=
          "none" &&
          !this.$refs["expansionContent" + index][0].$el.style) ||
        (this.$refs["expansionContent" + index][0].$el.style?.display ==
          "none" &&
          this.$refs["expansionContent" + index][0].$el.style)
      ) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    toggleChannel(item, value) {
      if (!this.$props.field.selected) {
        value.find(c => c == item.value)
          ? value.splice(value.indexOf(item.value), 1)
          : value.push(item.value);
      }

      if (this.$props.field.customEvent) {
        this.$emit("event", item.value);
      } else {
        this.$emit("event");
      }
    },
    highlightCode(code) {
      return highlight(code, languages[this.$props.field.language]);
    },
    async handleImport(files) {
      try {
        if (files != null) {
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            let res = await inbox().addAttachment(file);
            const split = res.split(".");
            const text = split[1] + "." + split.slice(2).join(".");
            this.files.push({ text: encodeURI(text), value: res });
            this.$attrs.value.push({ text: encodeURI(text), value: res });
            this.$emit("input", this.$attrs.value);
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    openImport() {
      this.$refs[this.$props.field.value + "filePicker"].value = "";
      this.$refs[this.$props.field.value + "filePicker"].click();
    },
    async removeFile(file) {
      await inbox().removeAttachment(file.value.split("|")[1]);
      this.files = this.files.filter(v => v.value != file.value);
      this.$attrs.value = this.$attrs.value.filter(v => v.value != file.value);
      this.$emit("input", this.$attrs.value);
    },

    toggleSelectionAll() {
      if (
        this.$attrs.value == null ||
        this.$attrs.value.length != this.$props.field.items().length
      ) {
        if (this.$props.field.itemValue)
          this.$attrs.value = this.$props.field
            .items()
            .map(v => v[this.$props.field.itemValue]);
        else this.$attrs.value = this.$props.field.items();
      } else {
        this.$attrs.value = [];
      }
      this.$emit("input", this.$attrs.value);
    },
    move(value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex,
      };
    },
    change(value) {
      if (value.removed) {
        let element = this.$attrs.value[this.dragged.from];
        this.$attrs.value.splice(this.dragged.from, 1);

        this.$attrs.value.splice(this.dragged.to, 0, element);
      }
    },

    editChip(item) {
      this.editingChipValue = item;
      this.editingChip = true;
      this.editingChipSelected = item;

      this.$nextTick(() => {
        this.$refs["editingChip" + item].focus();
      });
    },
    validateNewChip(item, type) {
      let regex =
        //eslint-disable-next-line
        type == "emails" || type == "cc" || type == "cco"
          ? /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
          : /^\+?[0-9]{6,}$/;

      if (regex.test(item.replaceAll(" ", ""))) {
        let aux = {
          type: type,
          value: item,
          oldValue: this.editingChipSelected,
        };
        this.$emit("updateItem", aux);

        this.editingChip = false;
        this.editingChipSelected = null;
        this.editingChipValue = "";

        this.$nextTick(() => {
          this.$refs.combobox.$refs.input.focus();
        });
      }
    },
    clickOutsideEditing() {
      if (this.$props.field.saveFocusOut) {
        this.validateNewChip(this.editingChipValue, this.$props.field.value);
      }
      this.editingChip = false;
      this.editingChipSelected = null;
      this.editingChipValue = "";
    },

    positionCursor(event, item) {
      this.editingChipValue = item;
      this.editingChip = true;
      this.editingChipSelected = item;

      this.$nextTick(() => {
        const textFieldRef =
          this.$refs["editingChip" + item].$el.querySelector("input");
        if (textFieldRef) {
          textFieldRef.focus();

          const cursorPos = this.getCursorPosition(textFieldRef, event, item);

          textFieldRef.setSelectionRange(cursorPos, cursorPos);
        }
      });
    },
    getCursorPosition(textField, event) {
      const rect = textField.getBoundingClientRect();
      const clickX = event.clientX - rect.left;

      const cursorPos = this.getCharacterIndexAtPosition(textField, clickX);

      return cursorPos;
    },

    getCharacterIndexAtPosition(element, xPosition) {
      const context = document.createElement("canvas").getContext("2d");
      const text = element.value;

      context.font =
        window.getComputedStyle(element).fontSize +
        " " +
        window.getComputedStyle(element).fontFamily;

      let totalWidth = 0;
      let index = 0;

      for (index = 0; index < text.length; index++) {
        const width = context.measureText(text.charAt(index)).width;
        totalWidth += width;

        if (totalWidth > xPosition) {
          break;
        }
      }

      return index;
    },
    changeNumberValue(value) {
      if (!value) {
        this.$emit("input", 0);
      } else {
        this.$emit("input", this.$attrs.value);
      }
    },
  },

  computed: {
    useSelectAll() {
      const isMultiple =
        this.$props.field.multiple && this.$props.field.multiple();

      return (
        isMultiple &&
        this.$props.field.items() !== null &&
        this.$props.field.items()?.length > 0
      );
    },
  },
};
</script>
<style lang="scss">
.v-radio.bigRadio label {
  color: var(--text-color);
  transition: 0.3s all;
}
.v-radio.bigRadio.v-item--active label {
  color: var(--text-color);
  font-weight: initial;
}
.v-radio.bigRadio.v-item--active {
  outline: var(--v-primary-base) 3px solid;
  box-shadow: 0px 0px 12px 0px rgba(0, 149, 255, 0.3);
}
.v-radio.bigRadio.v-item--active label h4 {
  color: var(--v-primary-base);
}
.v-radio.bigRadio > .v-input--selection-controls__input {
  display: none;
}
.v-input--selection-controls .v-radio.bigRadio > .v-label {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  height: 100%;
}
.customEditor .ql-toolbar.ql-snow {
  background-color: #fff;
}

.customEditor .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background-color: #fff;
}

.v-input__prepend-outer {
  margin-top: 1px !important;
}

.v-input__prepend-inner {
  margin-top: 5px !important;
}
.v-textarea {
  height: 100%;
  .v-input__control {
    height: 100%;
  }
}

.templatesIcon {
  right: 4px;
  bottom: 4px;
  position: absolute;
}

.my-editor {
  background: #2d2d2d;
  color: #ccc;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

.prism-editor__container {
  overflow: auto;
  height: 100%;
}
.prism-editor__container > textarea {
  height: 100%;
}

fieldset:not(.commentNode fieldset, .extensionInput.blur fieldset, .login--input fieldset, .search fieldset, .type-comment fieldset) /*fields of vuetify */,
.ProseMirror,
.theme--light.inputPanel .v-card,
.theme--dark.inputPanel .v-card {
  background: var(--v-uGrey-lighten1);

  .variant & {
    background: var(--v-background-accent) !important;
  }
}

.theme--light.inputPanel .v-card,
.theme--light .message-input {
  background: var(--v-background-base);
}

.theme--dark.inputPanel .v-card,
.theme--dark .message-input {
  // background: var(--v-background-darken1);
  background: var(--v-background-base);
}

.v-input__icon.v-input__icon--prepend-inner,
.v-input__prepend-inner {
  z-index: 1;
}

.v-input__prepend-inner,
.v-input__append-inner,
.v-input__append-outer,
.v-input__prepend-outer {
  align-self: center !important;
  margin-top: 0 !important;
  margin-right: 8px;
  margin-left: 5px;
  z-index: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px transparent !important;
}

.theme--dark input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

.theme--light input:-webkit-autofill,
.login--input input:-webkit-autofill {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
}

.login--input {
  fieldset {
    background: #e0e0e0;
  }
  .v-label {
    color: rgba(0, 0, 0, 0.6);
  }
}
.search {
  fieldset {
    background: var(--v-background-base);
  }

  &.variant {
    fieldset {
      background: var(--v-background-accent);
    }
  }
}
.v-input__prepend-outer:has(.noMargin) {
  margin: 0 !important;
}

.v-radio.v-item--active label {
  color: var(--v-primary-base);
  font-weight: 800;
}
.mdi-information,
.mdi-help-circle-outline {
  font-size: 21px !important;
}
.editable-chip .v-chip__close {
  font-size: 14px !important;
}
.theme--light .editable-chip span {
  color: #505253 !important;
}
.theme--dark .editable-chip span {
  color: #fff !important;
}

.editable-chip-container .v-select__selections {
  flex-wrap: nowrap;
  overflow-x: auto;
}
span.editable-chip.v-chip.v-chip--no-color.v-chip--removable.v-size--small {
  overflow: visible;
}

.FormsBasicLayout.theme--dark fieldset {
  background-color: var(--v-background-accent) !important;
}
.configuration-input input {
  color: var(--text) !important;
}
</style>
