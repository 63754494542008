<template>
  <FormBasicLayout
    size="xl"
    :showActions="false"
    :padding="'pa-0'"
    :closeMobile="false"
  >
    <template #form>
      <v-tabs
        v-if="$vuetify.breakpoint.mdAndDown"
        v-model="selectedTab"
        :vertical="!$vuetify.breakpoint.mdAndDown"
        :icons-and-text="$vuetify.breakpoint.mdAndDown"
        :show-arrows="$vuetify.breakpoint.mdAndDown"
        :centered="$vuetify.breakpoint.mdAndDown"
      >
        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          :class="[{ 'justify-start': !$vuetify.breakpoint.mdAndDown }]"
        >
          <v-icon v-if="!$vuetify.breakpoint.mdAndDown" left>
            {{ tab.icon }}
          </v-icon>
          <span>
            {{ $i18n.t(tab.text) }}
          </span>
          <v-icon v-if="$vuetify.breakpoint.mdAndDown">
            {{ tab.icon }}
          </v-icon>
        </v-tab>
      </v-tabs>

      <!-- Loader -->
      <div
        v-if="loading"
        style="width: 100%; height: 100%"
        class="d-flex align-center justify-center"
      >
        <Loader :size="150" />
      </div>

      <v-row
        v-if="!loading"
        no-gutters
        class="fill-height"
        :style="{
          height: $vuetify.breakpoint.mdAndDown
            ? selectedTab == 0
              ? 'calc(100% - 72px)'
              : 'calc(100% - 133px)'
            : null,
        }"
      >
        <v-col
          :cols="$vuetify.breakpoint.mdAndDown ? '12' : '9'"
          v-if="
            !$vuetify.breakpoint.mdAndDown ||
            ($vuetify.breakpoint.mdAndDown && selectedTab != 3)
          "
          style="max-height: 100%; overflow: auto"
        >
          <v-card
            elevation="0"
            class="pa-10 fill-height overflow-hidden"
            :style="
              $vuetify.theme.dark
                ? 'background-color: var(--v-background-base) !important'
                : ''
            "
          >
            <div
              class="d-flex flex-column fill-height align-content-start justify-center"
            >
              <div
                class="pa-0 pb-3"
                v-if="
                  !$vuetify.breakpoint.mdAndDown ||
                  ($vuetify.breakpoint.mdAndDown && selectedTab == 0)
                "
              >
                <div
                  class="d-flex align-center"
                  :class="$vuetify.breakpoint.mdAndDown ? 'flex-column' : null"
                >
                  <div
                    :style="{
                      width: !$vuetify.breakpoint.mdAndDown ? '10%' : null,
                    }"
                  >
                    <UserAvatar
                      ref="userAvatar"
                      :offset="$vuetify.breakpoint.mdAndDown ? 60 : 28"
                      :user="userInfo"
                      :status="userInfo.status"
                      :size="$vuetify.breakpoint.mdAndDown ? 180 : 90"
                      :dashboardAgent="true"
                    />
                  </div>

                  <div
                    :style="{
                      width: !$vuetify.breakpoint.mdAndDown ? '80%' : null,
                    }"
                  >
                    <v-row
                      no-gutters
                      :class="!$vuetify.breakpoint.mdAndDown ? 'pl-7' : null"
                    >
                      <v-col
                        cols="12"
                        class="d-flex align-center"
                        :class="
                          $vuetify.breakpoint.mdAndDown
                            ? 'flex-column py-3'
                            : null
                        "
                      >
                        <span class="text-h5 font-weight-bold primary--text">
                          {{ $i18n.t("Hi", { name: userInfo.name }) }}
                        </span>
                        <span
                          :class="
                            !$vuetify.breakpoint.mdAndDown ? 'ml-5' : null
                          "
                          style="font-size: 16px"
                        >
                          <span
                            class="text-body-1"
                            :style="{
                              color: $vuetify.theme.dark
                                ? '#fff !important'
                                : '#505253 !important',
                            }"
                          >
                            <v-icon color="primary" small>
                              mdi-phone-outline
                            </v-icon>
                            {{ userInfo.phone }}
                          </span>
                        </span>
                      </v-col>
                      <v-col
                        cols="12"
                        :class="$vuetify.breakpoint.mdAndDown ? 'py-3' : 'pt-1'"
                        :style="{
                          'text-align': $vuetify.breakpoint.mdAndDown
                            ? 'center'
                            : null,
                        }"
                      >
                        <span
                          class="font-weight-bold agent-title-color"
                          style="font-size: 16px"
                        >
                          {{
                            $i18n.t(generateText.text, {
                              value: generateText.param,
                            })
                          }}
                        </span>
                      </v-col>
                    </v-row>
                  </div>

                  <div style="width: 10%" v-if="!$vuetify.breakpoint.mdAndDown">
                    <BotPlaceholder
                      class="botPlaceholder pt-2 pr-2"
                      type="hello"
                      :showText="false"
                      justBot
                      :styles="{
                        transform: 'scaleX(-1)',
                        height: '90px',
                      }"
                      :showHeightBot="false"
                    />
                  </div>
                </div>
              </div>

              <!-- Interaction cards -->
              <div
                style="min-height: 170px !important"
                :class="$vuetify.breakpoint.mdAndDown ? 'fill-height' : null"
                v-if="
                  !$vuetify.breakpoint.mdAndDown ||
                  ($vuetify.breakpoint.mdAndDown && selectedTab == 1)
                "
              >
                <v-row no-gutters class="fill-height align-center">
                  <v-col
                    :cols="$vuetify.breakpoint.mdAndDown ? '6' : null"
                    v-for="(channel, index) in computedChannels"
                    :key="index"
                    style="position: relative; height: 170px"
                    :class="$vuetify.breakpoint.mdAndDown ? 'my-2' : null"
                  >
                    <v-hover v-slot:default="{ hover }">
                      <div style="width: 100%">
                        <v-fade-transition>
                          <InteractionCard
                            style="position: absolute; width: 97%"
                            :style="
                              $vuetify.theme.dark
                                ? 'background-color: var(--v-background-accent) !important'
                                : ''
                            "
                            v-if="!hover"
                            :channel="channel"
                            :inbound="interactionStats[channel.value + 'In']"
                            :outbound="interactionStats[channel.value + 'Out']"
                            :disabled="channel.disabled"
                          />
                        </v-fade-transition>
                        <v-fade-transition>
                          <InteractionCard
                            style="position: absolute; width: 97%"
                            :style="
                              $vuetify.theme.dark
                                ? 'background-color: var(--v-background-accent) !important'
                                : ''
                            "
                            v-if="hover"
                            :channel="channel"
                            :inbound="interactionStats[channel.value + 'In']"
                            :outbound="interactionStats[channel.value + 'Out']"
                            :time="
                              interactionStats[channel.value + 'Time'] || 0
                            "
                            :disabled="channel.disabled"
                          />
                        </v-fade-transition>
                      </div>
                    </v-hover>
                  </v-col>
                </v-row>
              </div>

              <!-- Charts -->
              <div
                class="d-flex fill-height py-5"
                :class="$vuetify.breakpoint.mdAndDown ? 'flex-column' : null"
                style="max-height: 100%"
                v-if="
                  !$vuetify.breakpoint.mdAndDown ||
                  ($vuetify.breakpoint.mdAndDown && selectedTab == 2)
                "
              >
                <div
                  class="pa-3"
                  :class="$vuetify.breakpoint.mdAndDown ? null : 'pl-0'"
                  :style="{
                    width: $vuetify.breakpoint.smAndDown
                      ? '100% !important'
                      : '60% !important',
                    height: '100% !important',
                  }"
                >
                  <v-card
                    class="d-flex flex-column fill-height"
                    :style="
                      $vuetify.theme.dark
                        ? 'background-color: var(--v-background-accent) !important'
                        : ''
                    "
                    style="
                      box-shadow: 0px 0px 10.143px 0px rgba(0, 0, 0, 0.15) !important;
                      border-radius: 20px;
                    "
                  >
                    <AutomaticChart
                      style="flex: 1"
                      class="pa-3"
                      ref="chart"
                      :viewOnly="true"
                      :chart="statsChart"
                      :sources="chartSources"
                      :globalSearch="chartFilters"
                      :externalData="[statsChartData]"
                    />
                    <div>
                      <v-row no-gutters class="pa-2 pt-0">
                        <v-col
                          cols="12"
                          class="d-flex justify-space-around flex-wrap"
                        >
                          <span
                            v-for="(item, index) in statsMetrics"
                            :key="index"
                            class="pa-1 text-caption d-flex algin-center"
                            style="cursor: pointer"
                            @click="clickLegendStats(item)"
                          >
                            <v-icon size="12" :color="item.color" class="px-1">
                              mdi-circle
                            </v-icon>
                            <span
                              :style="[
                                {
                                  'text-decoration': !item.active
                                    ? 'line-through'
                                    : null,
                                },
                                {
                                  color: $vuetify.theme.dark
                                    ? '#C4C4C4'
                                    : '#545454',
                                },
                              ]"
                            >
                              {{ $i18n.t(item.id) }}
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </div>

                <div
                  :style="{
                    width: $vuetify.breakpoint.smAndDown
                      ? '100% !important'
                      : '40% !important',
                  }"
                  class="pa-3 fill-height"
                  :class="$vuetify.breakpoint.mdAndDown ? null : 'pr-0'"
                >
                  <v-card
                    class="d-flex flex-column fill-height"
                    :style="
                      $vuetify.theme.dark
                        ? 'background-color: var(--v-background-accent) !important'
                        : ''
                    "
                    style="
                      box-shadow: 0px 0px 10.143px 0px rgba(0, 0, 0, 0.15) !important;
                      border-radius: 20px;
                    "
                  >
                    <AutomaticChart
                      style="flex: 1 1 100%"
                      class="px-3 agent-card-chart-status"
                      :viewOnly="true"
                      :chart="statusChart"
                      :sources="chartSources"
                      :dataFilters="chartFilters"
                      :externalData="[statusChartData]"
                    />
                  </v-card>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>

        <!-- Time line -->
        <v-col
          v-if="
            !$vuetify.breakpoint.mdAndDown ||
            ($vuetify.breakpoint.mdAndDown && selectedTab == 3)
          "
          :cols="$vuetify.breakpoint.mdAndDown ? '12' : '3'"
          :style="
            $vuetify.theme.dark
              ? 'background-color: var(--v-background-accent) !important'
              : ''
          "
          class="fill-height"
        >
          <v-row no-gutters class="pa-3 fill-height">
            <v-col cols="12" class="d-flex align-center pa-3 pb-0">
              <span
                class="font-weight-bold agent-title-color"
                style="font-size: 16px"
              >
                {{ $i18n.t("Recent activity") }}
              </span>

              <!-- Filter interactions -->
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon class="ml-2" v-bind="attrs" v-on="on">
                    <v-icon color="primary">mdi-filter-outline</v-icon>
                  </v-btn>
                </template>
                <v-card style="width: 155px">
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="pa-3"
                      v-for="(item, index) in timelineButtons"
                      :key="index"
                    >
                      <v-checkbox
                        v-model="timelineFilters[item.value]"
                        :label="$i18n.t(item.text)"
                        color="primary"
                        hide-details="auto"
                        class="ma-0"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-menu>

              <!-- Group time -->
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon class="ml-1" v-bind="attrs" v-on="on">
                    <v-icon color="primary">mdi-clock-outline</v-icon>
                  </v-btn>
                </template>
                <v-card style="width: 155px">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-list flat>
                        <v-list-item
                          v-for="(option, index) in groupTimes"
                          :key="index"
                          @click="handleTimeLineTime(option)"
                        >
                          <v-list-item-title
                            :class="
                              selectedGroupTime == option.time
                                ? 'font-weight-bold primary--text'
                                : ''
                            "
                          >
                            {{ option.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card>
              </v-menu>
            </v-col>

            <v-col
              cols="12"
              class="pa-3 fill-height overflow-auto"
              style="max-height: calc(100% - 36px) !important"
            >
              <div v-if="!loadingTimeline">
                <TimeLineItem
                  v-for="(event, index) in eventsComputed"
                  :key="index"
                  :dateText="getDateText(event.date)"
                  :events="[event.events]"
                  :showDay="showDay(index)"
                  :color="event.color"
                  :eventDate="getDayText(event.date)"
                  :filters="timelineFilters"
                />
              </div>

              <div
                v-if="loadingTimeline"
                style="width: 100%; height: 100%"
                class="d-flex align-center justify-center"
              >
                <Loader :size="150" />
              </div>
            </v-col>
          </v-row>
        </v-col>

        <!-- Close dialog in mobile -->
        <v-col
          cols="12"
          v-if="$vuetify.breakpoint.mdAndDown"
          class="d-flex pa-3 align-end"
          :style="{
            'background-color':
              selectedTab == 3
                ? $vuetify.theme.dark
                  ? '#505253'
                  : '#FAFAFA'
                : null,
          }"
        >
          <v-btn
            class="elevation-0"
            color="primary"
            rounded
            block
            @click="$emit('close')"
          >
            <span>{{ $i18n.t("Close") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </FormBasicLayout>
</template>

<script>
import {
  channels,
  luxon,
  useInboxStore,
  useAppStore,
  avatarUrl,
  formatNumber,
  backgroundColor,
} from "@/utils";
import AutomaticChart from "@/components/wallboards/AutomaticChart";
import InteractionCard from "@/components/home/AgentInteractionCard";
import TimeLineItem from "@/components/home/TimeLineAgentItem";

import UserAvatar from "@/components/users/UserAvatar.vue";

import Stats from "@/controllers/stats";
import User from "@/controllers/user";

import { dateToLocalTime } from "../../utils";

export default {
  name: "DashboardAgent",

  components: {
    AutomaticChart,

    InteractionCard,
    TimeLineItem,

    UserAvatar,
  },
  props: {
    username: { type: String, default: null },
    search: { type: Object, default: null },
  },
  data() {
    return {
      disabledColor: this.$vuetify.theme.dark ? "#E4E4E4A0" : "#00000060",
      stats: null,
      events: null,
      breaks: null,
      loading: false,
      channels,
      formatNumber,
      backgroundColor,
      useInboxStore: useInboxStore(),
      timelineButtons: [
        {
          color: "#FFF",
          icon: "mdi-account-circle-outline",
          text: "Login",
          value: "login",
          click: () => {
            this.timelineFilters["login"] = !this.timelineFilters["login"];
          },
        },
        {
          color: "#FFF",
          icon: "mdi-forum-outline",
          text: "Interactions",
          value: "interaction",
          click: () => {
            this.timelineFilters["interaction"] =
              !this.timelineFilters["interaction"];
          },
        },
        {
          color: "#FFF",
          icon: "mdi-coffee-outline",
          text: "Status",
          value: "status",
          click: () => {
            this.timelineFilters["status"] = !this.timelineFilters["status"];
          },
        },
      ],
      timelineFilters: {
        login: true,
        interaction: true,
        status: true,
      },
      chartFilters: {
        interval: 15,
        source: "query",
        start: luxon().now().toFormat("yyyy-MM-dd") + " 00:00:00",
        end: luxon().now().toFormat("yyyy-MM-dd") + " 23:59:59",
        channels: "",
        byResource: true,
        byChannel: true,
        resources: "",
      },
      chartSources: [
        {
          text: "dialer",
          icon: "mdi-account-outline",
          color: "pink lighten-1",
          value: "dialer",
        },
      ],
      timelineTexts: {
        LOGINstart: "Connected",
        LOGINend: "Disconnected",
        WRAPUPstart: "Start wrapup",
        WRAPUPend: "End wrapup",
        CALLOUTstart: "Start call out",
        CALLOUTend: "End call out",
        CALLINstart: "Start call in",
        CALLINend: "End call in",
        smsstart: "Start SMS",
        smsend: "End SMS",
        webchatstart: "Start Webchat",
        webchatend: "End Webchat",
        emailstart: "Start email",
        emailend: "End email",
        whatsappstart: "Start whatsapp",
        whatsappend: "End whatsapp",
        messengerstart: "Start messenger",
        messengerend: "End messenger",
        instagramstart: "Start instagram",
        instagramend: "End instagram",
      },
      timelineIcons: {
        LOGIN: "mdi-account-outline",
        WRAPUP: "mdi-pencil-outline",
        CALLOUT: "mdi-phone-outline",
        CALLIN: "mdi-phone-outline",
        sms: "mdi-cellphone",
        webchat: "mdi-forum-outline",
        email: "mdi-email-outline",
        whatsapp: "mdi-whatsapp",
        messenger: "mdi-facebook-messenger",
        instagram: "mdi-instagram",
      },
      userCampaigns: [],
      selectedTab: 0,
      tabs: [
        {
          text: "Dashboard",
          icon: "mdi-view-dashboard",
          value: 0,
        },
        {
          text: "Interactions",
          icon: "mdi-forum-outline",
          value: 1,
        },
        {
          text: "Charts",
          icon: "mdi-chart-line",
          value: 2,
        },
        {
          text: "Events",
          icon: "mdi-timeline-outline",
          value: 3,
        },
      ],
      statsMetrics: [
        { id: "Logged", color: "#81C4B2", active: true },
        { id: "Occupation", color: "#EF707A", active: true },
        { id: "Hold", color: "#61BDFF", active: true },
        { id: "Wrapup", color: "#FFBC58", active: true },
      ],
      message: {},
      groupTimes: [
        {
          text: "15 minutes",
          time: 15,
        },
        {
          text: "30 minutes",
          time: 30,
        },
        {
          text: "1 hour",
          time: 60,
        },
        {
          text: "2 hours",
          time: 120,
        },
        {
          text: "4 hours",
          time: 240,
        },
        {
          text: "6 hours",
          time: 360,
        },
        {
          text: "1 day",
          time: 1440,
        },
      ],
      selectedGroupTime: 15,
      loadingTimeline: false,
    };
  },
  created() {
    this.handleUserChange();
    this.handleMessage();
  },
  async mounted() {
    this.handleUserChange();
    this.handleMessage();
    if (this.$props.search)
      this.selectedGroupTime = this.$props.search.interval;
  },
  computed: {
    // Channels
    computedChannels() {
      let username = this.$props.username
        ? this.$props.username
        : useAppStore().user.username;
      let aux = [];

      // if (this.userCampaigns.length) {
      JSON.parse(JSON.stringify(this.channels)).forEach(channel => {
        channel.disabled = true;
        if (!["agent", "campaign"].includes(channel.value)) {
          channel.disabled = true;
          if (
            this.userCampaigns.length &&
            this.userCampaigns.find(c => c.channels.includes(channel.value)) &&
            this.userCampaigns.find(c =>
              c.members[channel.value]?.all.includes(username)
            )
          ) {
            channel.disabled = false;
          }
          aux.push(channel);
        }
      });

      // }

      aux.sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1));

      return aux;
    },

    userInfo() {
      let user = {};
      let contacts = [];

      contacts = useInboxStore().contacts;
      user = this.$props.username
        ? contacts.find(c => c.username === this.$props.username)
        : {
            ...useAppStore().user,
            status:
              useInboxStore().pause.status.name !== ""
                ? useInboxStore().pause.status.name
                : useInboxStore().status,
          };

      user = user ? user : {};

      return {
        username: user.username,
        name: user.name,
        phone: user.phone,
        image: avatarUrl(user.username, user.avatar),
        status:
          this.$props.username && user.status === ""
            ? user.connected
              ? "Connected"
              : "Websocket and Phone not connected"
            : user.status,
      };
    },

    // Events time line
    eventsComputed() {
      let list = [];
      if (this.events) {
        this.events.forEach(event => {
          let aux = {};
          let groupEvents = [];

          event.events.forEach(e => {
            let text = e.period.toLowerCase().includes("break")
              ? this.$i18n.t("Status") +
                " / " +
                this.getBreakIcon(e.period).text
              : this.timelineTexts[e.period + e.type];

            if (e.period.toLowerCase().includes("break")) {
              if (e.type === "end") text = "Unbreak " + text.split("/")[1];
              else e.color = this.getBreakIcon(e.period).color;
            }
            e.text = text;

            if (groupEvents.includes(text)) {
              aux[text].amount++;
            } else {
              groupEvents.push(text);
              aux[text] = {
                ...e,
                amount: 1,
              };
            }
          });

          if (Object.values(aux).length) {
            event.events = Object.values(aux);
            list.push(event);
          }
        });
      }

      // list.sort((a, b) => {
      //   let x = b.date.toLowerCase();
      //   let y = a.date.toLowerCase();

      //   if (x < y) {
      //     return -1;
      //   }
      //   if (x > y) {
      //     return 1;
      //   }

      //   return 0;
      // });
      list.reverse();

      return list;
    },

    // Breaks chart
    statusChart() {
      let metrics = [];
      let colors = {};

      if (this.breaks && this.breaks.length) {
        let aux = this.statusChartData;
        let keys = Object.keys(aux);
        keys.forEach(k => {
          metrics.push({ id: k });
          let iconColor =
            k === "available" || k === "Unavailable" || k === "Supervisor"
              ? ""
              : this.breaks.find(b => b.name === k)?.color;
          colors[k] = iconColor.includes("n2p-")
            ? this.$vuetify.theme.currentTheme[iconColor]
            : iconColor;
        });

        colors.available = "#CCCCFF";
        colors.Unavailable = "#FFCCCC";
      }

      return {
        lid: Date.now(),
        metrics: {
          metrics: metrics,
          group: { icon: "mdi-account-outline", name: "Agent" },
        },
        customMetrics: [],
        source: "dialer",
        type: "DoughnutChart",
        colors: colors,
        configs: {
          compareDates: false,
          legendPosition: "right",
          groupByCol: true,
          excluded: [],
          convertTo: "time",
          showCircleLegend: true,
        },
        by: "metric",
      };
    },
    statusChartData() {
      let aux = {};
      let loggedTime = 0;

      let totalBreakTime = 0;

      if (this.stats && this.stats[0]) {
        let statsBreaks = this.stats[0].breaks
          ? JSON.parse(this.stats[0].breaks)
          : [];

        statsBreaks.forEach(b => {
          if (Object.keys(b).length) {
            let keys = Object.keys(b);
            let values = Object.values(b);
            keys.forEach((k, i) => {
              if (values[i] > 0) {
                totalBreakTime += values[i];
                aux[k] = (aux[k] ? aux[k] : 0) + values[i];
              }
            });
          }
        });

        loggedTime = this.stats[0].logged - totalBreakTime;
      }

      return this.convertObjectTime({ ...aux, available: loggedTime });
    },
    showStatusChart() {
      let val = Object.values(this.statusChartData);

      return val.reduce((a, b) => a + b, 0);
    },

    // Stats chart
    statsChart() {
      return {
        by: "metrics",
        lid: Date.now(),
        metrics: {
          metrics: [],
          group: { icon: "mdi-account-outline", name: "Agent" },
        },
        source: "agent",
        type: "BarChart",
        colors: {
          Occupation: "#EF707A",
          Logged: "#81C4B2",
          Wrapup: "#FFBC58",
          Hold: "#61BDFF",
          Adherence: "#A562F0",
        },
        configs: {
          convertTo: "time",
          colorByCol: true,
          hideXGrid: true,
        },
      };
    },
    statsChartData() {
      let adherence = 0;
      let occupation = 0;
      let hold = 0;
      let wrapUp = 0;
      let logged = 0;

      if (this.stats && this.stats[0]) {
        adherence += this.stats[0].adherence;
        occupation += this.stats[0].occupation;
        hold += this.stats[0].holdIn + this.stats[0].holdOut || 0;
        wrapUp += this.stats[0].wrapUp;
        logged += this.stats[0].logged;
      }

      let aux = this.statsMetrics.filter(sm => sm.active).map(sm => sm.id);

      let obj = {
        Logged: aux.includes("Logged") ? logged : null,
        Occupation: aux.includes("Occupation") ? occupation : null,
        Hold: aux.includes("Hold") ? hold : null,
        Adherence: aux.includes("Adherence") ? adherence : null,
        Wrapup: aux.includes("Wrapup") ? wrapUp : null,
      };

      let result = {};
      for (let key in obj) {
        if (obj[key] !== null) {
          result[key] = obj[key];
        }
      }

      return result;
    },
    getStatsMetrics() {
      return this.statsMetrics.filter(sm => sm.active);
    },

    // interactions
    interactionStats() {
      let aux = this.stats;
      if (aux && aux[0]) {
        aux = aux[0];

        return {
          // Telephony
          telephonyIn: aux.inCalls || 0,
          telephonyOut: aux.outCalls || 0,
          telephonyTime: aux.calledIn + aux.calledOut || 0,
          telephonyTotal: aux.inCalls + aux.outCalls || 0,

          // Sms
          smsIn: aux.attendedSmsInteractions + aux.receivedAgentSms || 0,
          smsOut: aux.startedSmsInteractions || 0,
          smsTime: aux.smsInteractionTime || 0,
          smsTotal:
            aux.attendedSmsInteractions +
              aux.startedSmsInteractions +
              aux.receivedAgentSms || 0,

          //Email
          emailIn: aux.attendedEmailInteractions + aux.receivedAgentEmail || 0,
          emailOut: aux.startedEmailInteractions || 0,
          emailTime: aux.emailInteractionTime || 0,
          emailTotal:
            aux.attendedEmailInteractions +
              aux.startedEmailInteractions +
              aux.receivedAgentEmail || 0,

          // Webchat
          webchatIn:
            aux.attendedWebchatInteractions + aux.receivedAgentWebchat || 0,
          webchatTime: aux.webchatInteractionTime || 0,
          webchatTotal:
            aux.attendedWebchatInteractions + aux.receivedAgentWebchat || 0,

          // Whatsapp
          whatsappIn:
            aux.attendedWhatsappInteractions + aux.receivedAgentWhatsapp || 0,
          whatsappOut: aux.startedWhatsappInteractions || 0,
          whatsappTime: aux.whatsappInteractionTime || 0,
          whatsappTotal:
            aux.attendedWhatsappInteractions +
              aux.startedWhatsappInteractions +
              aux.receivedAgentWhatsapp || 0,

          // Messenger
          messengerIn:
            aux.attendedMessengerInteractions + aux.receivedAgentMessenger || 0,
          messengerOut: aux.startedMessengerInteractions || 0,
          messengerTime: aux.messengerInteractionTime || 0,
          messengerTotal:
            aux.attendedMessengerInteractions +
              aux.startedMessengerInteractions +
              aux.receivedAgentMessenger || 0,

          // Instagram
          instagramIn:
            aux.attendedInstagramInteractions + aux.receivedAgentInstagram || 0,
          instagramOut: aux.startedInstagramInteractions || 0,
          instagramTime: aux.instagramInteractionTime || 0,
          instagramTotal:
            aux.attendedInstagramInteractions +
              aux.startedInstagramInteractions +
              aux.receivedAgentInstagram || 0,
        };
      }

      return {
        telephonyIn: 0,
        telephonyOut: 0,
        telephonyTime: 0,
        telephonyTotal: 0,
        smsIn: 0,
        smsOut: 0,
        smsTime: 0,
        smsTotal: 0,
        emailIn: 0,
        emailOut: 0,
        emailTime: 0,
        emailTotal: 0,
        webchatIn: 0,
        webchatTime: 0,
        webchatTotal: 0,
        whatsappIn: 0,
        whatsappOut: 0,
        whatsappTime: 0,
        whatsappTotal: 0,
        messengerIn: 0,
        messengerOut: 0,
        messengerTime: 0,
        messengerTotal: 0,
        instagramIn: 0,
        instagramOut: 0,
        instagramTime: 0,
        instagramTotal: 0,
      };
    },

    generateText() {
      let res = {};
      let defaultMessages = [
        "Are you ready? Let’s go!",
        "Let's start building customer experiences together!",
        "What’s up? Let’s get started!",
        "Your presence makes our team stronger",
        "Your dedication inspires us all",
      ];

      let random = Math.floor(Math.random() * 4);

      if (this.message && random) {
        switch (this.message.metric) {
          case "adherence":
          case "occupation":
            res.text =
              "You were value longer in " +
              this.message.metric +
              " than yesterday";
            res.param = this.calculateTimeDiff(
              this.message.valueToday,
              this.message.valueYesterday
            );

            break;

          case "attended":
          case "started":
          case "finished":
            res.text =
              "You have " +
              this.message.metric +
              " value interactions more than yesterday";
            res.param = this.message.valueToday - this.message.valueYesterday;
            break;
        }
      } else {
        res.text =
          defaultMessages[Math.floor(Math.random() * defaultMessages.length)];
        res.param = "";
      }

      return res;
    },
  },
  methods: {
    async getStats() {
      let params = {
        start: luxon().now().toFormat("yyyy-MM-dd") + " 00:00:00",
        end: luxon().now().toFormat("yyyy-MM-dd") + " 23:59:59",
        interval: 15,
        source: "agent",
        metrics: "",
        resources: this.$props.username
          ? this.$props.username
          : useAppStore().user.username,
        channels: "",
        byResource: false,
        byChannel: false,
        customMetrics: [],
        total: true,
      };
      if (this.$props.search) {
        params.start = this.$props.search.start;
        params.end = this.$props.search.end;
        params.interval = this.$props.search.interval;
      }

      let res = await Stats.getStatsBySource(params);

      return res;
    },
    async getAgentEvents(groupTime = 15) {
      let params = this.$props.search
        ? {
            end: this.$props.search.end,
            start: this.$props.search.start,
            agent: this.$props.username
              ? this.$props.username
              : useAppStore().user.username,
            interval: groupTime,
          }
        : {
            end: luxon().now().toFormat("yyyy-MM-dd") + " 23:59:59",
            start: luxon().now().toFormat("yyyy-MM-dd") + " 00:00:00",
            agent: this.$props.username
              ? this.$props.username
              : useAppStore().user.username,
            interval: groupTime,
          };
      let res = await Stats.getAgentEvent(params);

      return res;
    },
    convertObjectTime(obj) {
      let keys = Object.keys(obj);
      let values = Object.values(obj);
      let aux = {};

      keys.forEach((k, i) => {
        aux[k] = values[i];
      });

      return aux;
    },
    getBreakIcon(period) {
      let name = period.replace("BREAK", "");
      if (name === "Supervisor") {
        return {
          icon: "mdi-monitor-eye",
          text: "Supervisor",
          color: "var(--v-n2p-purple-base)",
        };
      }
      if (name === "Unavailable") {
        return {
          icon: "mdi-cancel",
          text: "Unavailable",
          color: "var(--v-error-base)",
        };
      }
      let aux = this.breaks ? this.breaks.find(b => b.name === name) : {};

      return {
        icon: aux?.icon,
        text: this.$i18n.te(aux?.name) ? this.$i18n.t(aux?.name) : aux?.name,
        color: aux?.color,
      };
    },
    convertSecondsToTimeFormat(timeInSeconds) {
      if (!timeInSeconds) return "00:00:00";

      timeInSeconds = (timeInSeconds + "").replace(",", "");
      let hours = Math.floor(timeInSeconds / 3600);
      let minutes = Math.floor((timeInSeconds - hours * 3600) / 60);
      let seconds = timeInSeconds - hours * 3600 - minutes * 60;
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      return hours + ":" + minutes + ":" + seconds;
    },
    async handleUserChange() {
      this.loading = true;
      let auxUsername = this.$props.username ? this.$props.username : null;

      let breaks = await User.getStatuses(auxUsername);
      this.breaks = breaks.res.data.result;

      this.stats = await this.getStats();
      this.events = await this.getAgentEvents(this.selectedGroupTime);

      let inbox = await User.getInbox(auxUsername);

      this.userCampaigns = Object.values(inbox.res.data.result.campaigns);

      this.loading = false;
    },
    clickLegendStats(item) {
      item.active = !item.active;
      this.$refs.chart.$refs.chart.$children[0].$children[0].chart.update();
    },
    calculateTimeDiff(time1, time2) {
      const diff = Math.abs(time1 - time2);
      let result;

      if (diff >= 3600) {
        const hours = Math.floor(diff / 3600);
        result = hours + " " + this.$i18n.t("hour" + (hours > 1 ? "s" : ""));
      } else if (diff >= 60) {
        const minute = Math.floor(diff / 60);
        result =
          minute + " " + this.$i18n.t("minute" + (minute > 1 ? "s" : ""));
      } else {
        result = diff + " " + this.$i18n.t("second" + (diff > 1 ? "s" : ""));
      }

      return result;
    },

    async handleMessage() {
      let res = await User.getDashboardText(
        this.$props.username
          ? this.$props.username
          : useAppStore().user.username
      );
      let arrayResponse = res.res.data.result;

      this.message =
        arrayResponse[Math.floor(Math.random() * arrayResponse.length)];
    },
    async handleTimeLineTime(option) {
      if (this.selectedGroupTime != option.time) {
        this.selectedGroupTime = option.time;
        this.loadingTimeline = true;

        this.events = await this.getAgentEvents(option.time);

        this.loadingTimeline = false;
      }
    },
    getDateText(date) {
      if (this.selectedGroupTime >= 1440) {
        return dateToLocalTime(date).plus({ days: 1 }).toFormat("dd MMM");
      }

      return dateToLocalTime(date).toLocaleString(luxon().TIME_SIMPLE);
    },
    getDayText(date) {
      return dateToLocalTime(date).toFormat("dd MMM");
    },
    showDay(index) {
      let dayAux = "";

      if (!this.$props.search || this.selectedGroupTime >= 1440) {
        return false;
      }
      if (
        index === 0 &&
        this.checkIfHasEventsWithFilters(this.eventsComputed[0].events)
      )
        return true;
      else {
        dayAux = this.getPreviousDate(index);

        let date1 = dateToLocalTime(
          this.eventsComputed[index - 1].date
        ).toFormat("dd MMM");
        let date2 = dateToLocalTime(this.eventsComputed[index].date).toFormat(
          "dd MMM"
        );

        if (date1 != date2 || dayAux != date2) {
          return !this.checkIfHasEventsWithFilters(
            this.eventsComputed[index].events
          )
            ? false
            : this.checkIfHasEventsWithFilters(
                this.eventsComputed[index - 1].events
              ) && date1 == date2
            ? false
            : true;
        }
      }

      return false;
    },
    checkIfHasEventsWithFilters(events) {
      return events.filter(e => {
        if (
          (this.timelineFilters.login && e.period === "LOGIN") ||
          (this.timelineFilters.interaction &&
            (e.period === "WRAPUP" ||
              channels.some(c => e.period === c.value) ||
              e.period.toLowerCase().includes("call"))) ||
          (this.timelineFilters.status &&
            e.period.toLowerCase().includes("break"))
        ) {
          return true;
        }

        return false;
      }).length;
    },
    getPreviousDate(index) {
      let date = dateToLocalTime(this.eventsComputed[index].date).toFormat(
        "dd MMM"
      );
      for (let i = index; i >= 0; i--) {
        let auxDate = dateToLocalTime(this.eventsComputed[i].date).toFormat(
          "dd MMM"
        );
        if (date != auxDate) {
          return auxDate;
        }
      }

      return date;
    },
  },
  watch: {
    "$props.username"() {
      this.handleUserChange();
    },
  },
};
</script>
<style lang="scss">
.zoom {
  transition: transform 0.5s;
}
.zoom:hover {
  transform: scale(1);
  z-index: 1;
}

.zoom .individual-text,
.zoom .total-text {
  transition: all 0s;
  font-size: 1.5em;
  font-weight: 400;
}

.zoom:hover .individual-text,
.zoom:not(:hover) .total-text {
  opacity: 1;
  height: 100%;
}

.zoom:hover .total-text,
.zoom:not(:hover) .individual-text {
  opacity: 0;
  height: 0;
}

.circular-- {
  @mixin before-pseudo($background) {
    stroke: var(--#{$background}) !important;
    opacity: 0.4;
  }

  $channels: telephony, sms, whatsapp, messenger, email, webchat, instagram,
    agent, campaign;

  @each $channel in $channels {
    &#{$channel} {
      .v-progress-circular__underlay {
        @include before-pseudo($channel);
      }
    }
  }
}

.timeline-card-border {
  min-width: 4px !important;
  background-color: var(--v-primary-lighten2);
  margin: 4px 0;
  margin-left: 8px;
}

.theme--light .agent-title-color {
  color: #9b9d9e !important;
}

.botPlaceholder svg {
  height: 100% !important;
}

.agent-card-chart-status canvas {
  max-width: 100%;
}
</style>
